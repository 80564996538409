import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, SlideOver } from '@GxT-Team/gxt-components';
import { DocumentDetailProps, ViewSessionDataSlideOverProps } from './types';
import { getDateTime } from '../../../../helpers/catalogHelpers';
import { getMappingSessionDetailsById } from '../../../../services/api';
import { MappingType } from '../../../../services/api/types';

const ViewSessionDataSlideOver: FC<ViewSessionDataSlideOverProps> = ({
  handleClose,
  isOpen,
  sessionId,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ['mappingSessionDetails', sessionId],
    queryFn: () => getMappingSessionDetailsById({ id: sessionId }),
  });

  const DocumentDetail = ({ label, value }: DocumentDetailProps) => (
    <div className="flex flex-col gap-4 border-b w-full pb-4">
      <h1 className="font-normal">{label}</h1>
      <p className="font-normal">{value}</p>
    </div>
  );

  return (
    <SlideOver isOpen={isOpen} onClose={handleClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        Document Details
      </SlideOver.Header>
      {!isLoading && data ? (
        <div className="w-full font-normal space-y-4">
          <DocumentDetail label="Document Name" value={data.data.title} />
          <DocumentDetail label="Author(s)" value={data.data.authors.join(', ')} />
          <DocumentDetail label="Reviewer(s)" value={data.data.validators.join(', ')} />
          <DocumentDetail
            label="Created On"
            value={getDateTime(data.data.createdAt) + ' By ' + data.data.createdBy}
          />
          <DocumentDetail label="Last Date Modified" value={getDateTime(data.data.lastUpdatedAt)} />
          <DocumentDetail
            label="Generating AI Suggestions Status"
            value={
              data.data.mappingType === MappingType.AI_AUTHORING ? data.data.autowriteStatus : 'N/A'
            }
          />
          <DocumentDetail
            label="Generated AI Suggestions"
            value={
              data.data.mappingType === MappingType.AI_AUTHORING
                ? data.data.totalSuggestionsCount
                : 'N/A'
            }
          />
          <DocumentDetail label="Source IDs" value={data.data.sourceFileIds.join(', ')} />
        </div>
      ) : (
        <>Loading...</>
      )}
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default ViewSessionDataSlideOver;
