import { Button, Dropdown, Modal } from '@GxT-Team/gxt-components';
import SvgIcon from '../../../../components/elements/SvgIcon';
import { useEffect, useMemo, useState } from 'react';
import { GeneralizationDocument, ImageSuggestions } from '../../../../types';
import { ListItem } from '../../../../hooks/types';

type NewSuggestionModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  imageSuggestions: ImageSuggestions;
  imageSuggestionsFiles: GeneralizationDocument[];
  targetImageNodeId: string | undefined;
  handleSourceNodeIds: (nodeIds: string[]) => void;
  isSavingSuggestions: boolean;
};

const FindFigureModal = ({
  isOpen,
  handleClose,
  imageSuggestions,
  imageSuggestionsFiles,
  targetImageNodeId,
  handleSourceNodeIds,
  isSavingSuggestions,
}: NewSuggestionModalProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lockSourceFile, setLockSourceFile] = useState(false);
  const lockType = lockSourceFile ? 'lock' : 'unlock';
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<ListItem | null>(null);

  const getFilteredImages = (selectedValue: number) => {
    const imageList = imageSuggestions[selectedValue] ?? [];
    return imageList.filter(({ targetNodeId }) => targetNodeId === targetImageNodeId);
  };

  const images = useMemo(
    () => getFilteredImages(Number(selectedDropdownValue?.value || 0)),
    [imageSuggestions, selectedDropdownValue, targetImageNodeId],
  );

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const getSourceFileId = () => {
    const id = images[currentIndex]?.sourceNodeId;
    handleSourceNodeIds([id]);
  };

  const closeModal = () => {
    setCurrentIndex(0);
    handleClose();
  };

  const filesList = useMemo(
    () =>
      imageSuggestionsFiles.map((file) => ({
        value: { text: file.filename, value: file.id.toString() },
      })),
    [imageSuggestionsFiles],
  );

  const handleDropdownSelect = (option: ListItem) => {
    if (option.value === selectedDropdownValue?.value) return;

    setSelectedDropdownValue(option);
    setCurrentIndex(0);
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [targetImageNodeId]);

  useEffect(() => {
    if (!filesList.length) return;
    setSelectedDropdownValue(filesList[0].value);
  }, [filesList]);

  useEffect(() => {
    getSourceFileId();
  }, [selectedDropdownValue, currentIndex]);

  const renderOption = (item: ListItem | null) => {
    if (!item) return null;
    return (
      <>
        <span
          className="cursor-pointer truncate"
          data-testid={`dropdown-item-${item.value}`}
          onClick={() => handleDropdownSelect(item)}>
          {item?.text}
        </span>
      </>
    );
  };

  const sourceFilesDropdownTest = (
    <div className="w-96 flex-1">
      <Dropdown
        type="select"
        options={filesList}
        dropdownIcon
        disabled={lockSourceFile}
        initialValue={{ value: selectedDropdownValue }}
        renderOption={(option) => renderOption(option?.value)}
        position="bottom"
        onSelect={(option) => option.value && handleDropdownSelect(option.value)}
        equalityFn={(a, b) => a?.value === b?.value}
        customStyles={{
          container: { height: '100%', width: '100%' },
          button: {
            width: '100%',
          },
          placeholder: {
            textAlign: 'left',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            justifyContent: 'left',
            display: 'block',
          },
          itemsWrapper: { width: '100%' },
          item: {
            justifyContent: 'space-between',
          },
        }}
      />
    </div>
  );

  const handleLockedFileChange = () => {
    setLockSourceFile((prev) => !prev);
  };

  const lockedFileSwitchTest = (
    <Button
      variant={lockType === 'lock' ? 'primary' : 'secondary'}
      onClick={handleLockedFileChange}
      disabled={!true}>
      <div className="py-0.5">
        <SvgIcon iconType={lockType} />
      </div>
    </Button>
  );

  return (
    <Modal
      isOpen={isOpen}
      maxWidth="866px"
      maxHeight="650px"
      overlay={false}
      overlayClassName="!z-0 !w-[660px] 2xl:!w-[760px] 3xl:!w-[866px] !h-[600px] 3xl:!h-[650px] !top-[270px] !left-[120px]"
      className="!w-[660px] 2xl:!w-[760px] 3xl:!w-full !h-[600px] 3xl:!h-[650px] !p-0 !shadow-lg !rounded-none">
      <Modal.ModalHeader className="w-full p-0">
        <Modal.ModalHeaderHeading className="bg-primary_bg_color !text-white p-6">
          <div className="flex justify-between items-center">
            <h1>Find Figure</h1>
            <SvgIcon iconType="cross" fill="white" onClick={closeModal} />
          </div>
        </Modal.ModalHeaderHeading>

        <Modal.ModalHeaderBody>
          <div className="flex flex-col items-center p-6 gap-5">
            <div className="w-full flex gap-5">
              {sourceFilesDropdownTest}
              {lockedFileSwitchTest}
            </div>
            <div className="w-full h-[280px] 3xl:h-[340px]">
              {images.length ? (
                <img
                  src={images[currentIndex].sourceValue}
                  alt={`Image ${currentIndex + 1}`}
                  className="object-contain w-full h-full"
                  loading="lazy"
                />
              ) : (
                <p className="w-full h-full flex items-center justify-center font-bold text-gray-600">
                  There are no image suggestion!
                </p>
              )}
            </div>
          </div>
        </Modal.ModalHeaderBody>
      </Modal.ModalHeader>
      <Modal.ModalFooter className="p-4">
        <div className="flex justify-between w-full">
          <Button
            variant="secondary"
            onClick={handlePrevious}
            disabled={currentIndex === 0 || isSavingSuggestions}>
            <SvgIcon iconType="navigate-back" fill="black" className="text-black" />{' '}
            <span>Previous Figure</span>
          </Button>
          <Button
            variant="secondary"
            onClick={handleNext}
            disabled={
              currentIndex === images.length - 1 || images.length === 0 || isSavingSuggestions
            }>
            <span>Next Figure</span>
            <SvgIcon iconType="navigate-next" />
          </Button>
        </div>
      </Modal.ModalFooter>
    </Modal>
  );
};

export default FindFigureModal;
