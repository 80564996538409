import { FC, useRef, useEffect, useContext, useMemo, Dispatch, SetStateAction } from 'react';
import { getCatalogs } from '../services/apiCalls';
import { toast } from 'react-toastify';
import { useInfiniteScroll } from '../hooks/useInfiniteScrolling';
import {
  CatalogFilterType,
  ISourceDocument,
  ITargetDocument,
  SourceDocumentType,
  SourceDocumentTypeEnum,
  FormattedOptions,
  SourceModalFilterType,
  AutomappingScreenFilterType,
} from '../types';
import { CatalogContext } from '../contexts/CatalogContext';
import { Table } from './table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { CatalogSelectField } from './elements/CatalogSelectField';
import { updateParams } from '../helpers/paramsHelpers';
import SvgIcon from './elements/SvgIcon';
import { TableHeader } from '@GxT-Team/gxt-components';
import { archiveCatalog } from '../services/api';

export interface ISourceDocuments {
  activeTab?: string;
  filters: CatalogFilterType | SourceModalFilterType | AutomappingScreenFilterType;
  setFilters: (key: keyof (CatalogFilterType | SourceModalFilterType), value: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  selectedSourceDocuments?: number[];
  setSelectedSourceDocuments?: (id: number[]) => void;
  selectedTargetId?: number | null;
  setSelectedTarget?: (target: number | null) => void;
  refreshFiles?: boolean;
  setRefreshFiles?: (refreshFiles: boolean) => void;
  actionsColumn?: boolean;
  originPath?: string;
  page?: boolean;
  selectAllOption?: boolean;
  setDropdownValues?: Dispatch<SetStateAction<FormattedOptions>>;
  getDistinctProgramFilters?: (response: any) => void;
  setPreviewDocId?: (id: number) => void;
  isFileInterective?: (isLoading: boolean) => void;
  openPreviewModal?: () => void;
}

const RECORDS_PER_PAGE = 100;
export const SourceDocuments: FC<ISourceDocuments> = ({
  filters,
  setIsLoading,
  selectedSourceDocuments,
  setSelectedSourceDocuments,
  selectedTargetId,
  setSelectedTarget,
  refreshFiles = false,
  setRefreshFiles = (_refreshFiles) => {},
  actionsColumn = true,
  // setFilters,
  activeTab,
  originPath,
  page,
  selectAllOption,
  getDistinctProgramFilters,
  setPreviewDocId,
  isFileInterective,
  openPreviewModal,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { setSourceFiles } = useContext(CatalogContext);

  const handleSelectedFiles = (doc: ITargetDocument, type?: SourceDocumentType) => {
    const { id } = doc;
    if (
      type === SourceDocumentTypeEnum.Source &&
      selectedSourceDocuments &&
      setSelectedSourceDocuments
    ) {
      if (selectedSourceDocuments.includes(id)) {
        setSelectedSourceDocuments(selectedSourceDocuments.filter((file) => file !== id));
      } else {
        setSelectedSourceDocuments([...selectedSourceDocuments, id]);
      }
    } else if (setSelectedTarget) {
      setSelectedTarget(id);
    }
  };

  const deleteSource = (id: number) => {
    archiveCatalog([id])
      .then((res) => {
        toast.success(res.data.message);
        refreshData();
      })
      .catch((res) => {
        toast.error(res.data.message);
      })
      .finally(() => {});
  };

  // const getColumnName = (column: string) => {
  //   if (column === 'documentTitle') {
  //     return 'title';
  //   } else {
  //     return [
  //       'firstName',
  //       'documentCat',
  //       'version',
  //       'program',
  //       'title',
  //       'id',
  //       'documentName',
  //     ].includes(column)
  //       ? column
  //       : '';
  //   }
  // };

  const { data, refreshData, isDataLoading, response } = useInfiniteScroll(
    { type: '', statusType: '' },
    containerRef,
    getCatalogs,
    RECORDS_PER_PAGE,
    {
      ...filters,
      // ...(filters.columnSort && { columnSort: getColumnName(filters.columnSort) }),
    },
    setSourceFiles,
  );

  useEffect(() => {
    getDistinctProgramFilters && getDistinctProgramFilters(response);
  }, [response]);

  useEffect(() => {
    page && originPath && updateParams({ basePath: originPath, filters, activeTab });
  }, [filters, activeTab]);

  useEffect(() => {
    setIsLoading(isDataLoading);
  }, [isDataLoading]);

  const handleColumnSort = (_sort: string, _column: string) => {
    // setFilters('columnSort', column);
    // setFilters('sortType', sort);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const catalogIds = data.map((catalog: any) => catalog.id);
    const selectedSourceIds: number[] = event.target.checked
      ? [...new Set([...(selectedSourceDocuments || []), ...catalogIds])]
      : (selectedSourceDocuments &&
          selectedSourceDocuments.filter((id) => !catalogIds.includes(id))) ||
        [];

    setSelectedSourceDocuments && setSelectedSourceDocuments(selectedSourceIds);
  };

  const allDocsChecked = useMemo(() => {
    if (data.length === 0) return false;
    return data
      .map((item: any) => item.id)
      .every((id: number) => selectedSourceDocuments?.includes(id));
  }, [data, selectedSourceDocuments]);

  const handlePreviewDocument = (id: number, documentType: boolean) => {
    setPreviewDocId && setPreviewDocId(id);
    openPreviewModal && openPreviewModal();
    isFileInterective && isFileInterective(documentType);
  };

  const columnHelper = createColumnHelper<ISourceDocument>();

  const columns = useMemo(() => {
    const baseColumns: any = [
      columnHelper.accessor('id', {
        header: () =>
          selectAllOption ? (
            <input type="checkbox" checked={allDocsChecked} onChange={handleSelectAll} />
          ) : (
            ''
          ),
        cell: ({ row }) => (
          <CatalogSelectField
            type="checkbox"
            record={row}
            handleChange={handleSelectedFiles}
            checked={[selectedTargetId, ...(selectedSourceDocuments || [])].includes(
              row.original.id,
            )}
          />
        ),
        size: 10,
        maxSize: 10,
      }),
      columnHelper.accessor('id', {
        header: () => (
          <TableHeader ColumnName="id" Title="ID" handleColumnSort={handleColumnSort} />
        ),
        cell: (info) => info.getValue(),
        size: 60,
      }),
      columnHelper.accessor('documentName', {
        header: () => (
          <TableHeader
            ColumnName="documentName"
            Title="Document Name"
            handleColumnSort={handleColumnSort}
          />
        ),
        cell: (info) => info.getValue(),
        size: 700,
      }),
      columnHelper.accessor('program', {
        header: () => (
          <TableHeader ColumnName="program" Title="Program" handleColumnSort={handleColumnSort} />
        ),
        cell: (info) => info.getValue(),
        size: 30,
      }),
      columnHelper.accessor('version', {
        header: () => (
          <TableHeader ColumnName="version" Title="Version" handleColumnSort={handleColumnSort} />
        ),
        cell: (info) => info.getValue(),
        size: 30,
      }),
      columnHelper.accessor('documentCat', {
        header: () => (
          <TableHeader
            ColumnName="documentCat"
            Title="Category"
            handleColumnSort={handleColumnSort}
          />
        ),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('documentAuthor', {
        header: () => (
          <TableHeader
            ColumnName="firstName"
            Title="Uploaded By"
            handleColumnSort={handleColumnSort}
          />
        ),
        cell: (info) => {
          const createdBy = info.row.original.createdBy;
          const fullName = createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '';
          return fullName;
        },
      }),
    ];

    if (actionsColumn) {
      baseColumns.push(
        columnHelper.accessor('id', {
          header: () => 'Actions',
          cell: ({ row }) => (
            <div className="space-x-2">
              {setPreviewDocId && (
                <button
                  data-testid="preview-document-btn"
                  onClick={() => {
                    handlePreviewDocument(
                      row.original.isInteractive
                        ? row.original.id
                        : row.original.nonParsedCatalogId,
                      row.original.isInteractive,
                    );
                  }}>
                  <SvgIcon iconType="previewDocument" />
                </button>
              )}
              <DeleteConfirmationModal deleteHandler={deleteSource} id={row.original.id} />
            </div>
          ),
          size: 10,
          maxSize: 10,
        }),
      );
    }

    return baseColumns;
  }, [selectedTargetId, selectedSourceDocuments, data]);

  useEffect(() => {
    refreshFiles && refreshData();
    setRefreshFiles(false);
  }, [refreshFiles]);

  return (
    <div
      className="mt-0 overflow-y-auto overflow-hidden my-4"
      style={{ height: '60vh' }}
      ref={containerRef}>
      <Table data={data} columns={columns} rowSelectionCallback={handleSelectedFiles} />
    </div>
  );
};
