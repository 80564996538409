import { INavItem } from '@GxT-Team/gxt-components/dist/components/navitem/Navitem';
import {
  // Training,
  Catalog,
  Settings,
  Home,
  Logout,
  ProjectSettings,
} from '../assets/icons/sidebar';

export const NAVBAR_ITEMS: INavItem[] = [
  {
    navigateTo: '/',
    text: 'Home',
    Icon: Home,
  },
  // Currently the catalog list is not being used in the application.
  // If in future we need to use catalog list, we can uncomment this line.
  // {
  //   navigateTo: '/list',
  //   text: 'Catalog',
  //   Icon: Catalog,
  // },
  {
    navigateTo: '/catalog',
    text: 'Catalog',
    Icon: Catalog,
  },
  {
    navigateTo: '/project-settings',
    text: 'Project Settings',
    Icon: ProjectSettings,
  },
  // {
  //   navigateTo: '/auto-mapped',
  //   text: 'Template Training',
  //   Icon: Training,
  // },
  // {
  //   navigateTo: '/auto-mapping',
  //   text: 'Template Training 2',
  //   Icon: Training,
  // },
];

export const FOOTER_ITEMS: INavItem[] = [
  {
    navigateTo: '/settings',
    text: 'Settings',
    Icon: Settings,
  },
  {
    navigateTo: '/logout',
    text: 'Logout',
    Icon: Logout,
  },
];
