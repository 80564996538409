import { Button, Modal } from '@GxT-Team/gxt-components';

type SaveChangesModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSaveChanges: () => void;
  handleQuit: () => void;
};

const SaveChangesModal = ({
  isOpen,
  handleClose,
  handleSaveChanges,
  handleQuit,
}: SaveChangesModalProps) => {
  return (
    <>
      <Modal isOpen={isOpen} maxWidth="30.8125rem" onClose={handleClose}>
        <Modal.ModalHeader>
          <Modal.ModalHeaderHeading>Exit without saving?</Modal.ModalHeaderHeading>
          <Modal.ModalHeaderBody>
            Some updates may not have saved, do you want to save changes before exiting?
          </Modal.ModalHeaderBody>
        </Modal.ModalHeader>
        <Modal.ModalFooter>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save
          </Button>
          <Button variant="negative" onClick={handleQuit}>
            {"Don't Save"}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.ModalFooter>
      </Modal>
    </>
  );
};

export default SaveChangesModal;
