// React related imports
import { useContext } from 'react';

// Context and Hooks
import { GeneralizationContext } from '../../contexts/GeneralizationContext';

// Types
import { SourcesActionsProps } from './types';

// Others
import { Button, Dropdown } from '@GxT-Team/gxt-components';
import { SourceVersion } from '../../hooks/types';
import { getDropdownOptions } from '../../helpers/utils';

const SourcesActions = ({
  navigateAddSource,
  sourceFilesDropdown,
  lockedFileSwitch,
  handleSourceToggle,
  displayLockFileButton,
}: SourcesActionsProps) => {
  const versionOption: Record<string, SourceVersion> = { original: 'Original', parsed: 'Parsed' };
  const { isOriginalAvailable, isParsed } = useContext(GeneralizationContext);
  return (
    <>
      <h2 className="text-primary-300 mb-2">Sources</h2>
      <div className="flex items-center space-x-3">
        {sourceFilesDropdown}
        {displayLockFileButton && lockedFileSwitch}
        <Dropdown
          customStyles={{
            placeholder: { color: 'var(--neutral-400)' },
            itemsWrapper: { zIndex: 100, width: '100%' },
          }}
          disabled={!isOriginalAvailable}
          dropdownIcon
          initialValue={
            isParsed ? { value: versionOption.parsed } : { value: versionOption.original }
          }
          options={getDropdownOptions(Object.values(versionOption))}
          onSelect={handleSourceToggle}
          position="bottom"
          type="select"
        />
        <Button variant="secondary" data-testid="add-source-button" onClick={navigateAddSource}>
          Add Sources
        </Button>
      </div>
    </>
  );
};

export default SourcesActions;
