import { AlertMessage, Badge } from '@GxT-Team/gxt-components';
import { AlertMessageProps } from '@GxT-Team/gxt-components/dist/components/alertMessage/AlertMessage';
import { useContext } from 'react';
import { CatalogContext } from '../../../../contexts/CatalogContext';
import { UPLOAD_MESSAGES, UPLOAD_ALERT_TYPES } from '../../util/constants';

const CatalogUploadAlertMessage = ({
  totalFileCount,
  onReportClick = () => {},
  error,
  success,
  pending,
}: {
  totalFileCount: number;
  onReportClick?: () => void;
  error: number;
  success: number;
  pending: number;
}) => {
  const { setCatalogFilesUploadStatus } = useContext(CatalogContext);

  const alertProps: AlertMessageProps = {
    linkText: 'View Report',
    onLinkClick: onReportClick,
    alertMessage: '',
    display: totalFileCount > 0,
    onClose: () => pending === 0 && setCatalogFilesUploadStatus([]),
  };

  if (success === totalFileCount) {
    alertProps.alertType = UPLOAD_ALERT_TYPES.SUCCESS;
    alertProps.alertMessage = UPLOAD_MESSAGES.ALL_SUCCESS;
  } else if (error === totalFileCount) {
    alertProps.alertType = UPLOAD_ALERT_TYPES.ERROR;
    alertProps.alertMessage = UPLOAD_MESSAGES.ALL_ERROR;
  } else {
    alertProps.alertType = UPLOAD_ALERT_TYPES.WARNING;
    alertProps.alertMessage = UPLOAD_MESSAGES.PARTIAL_SUCCESS(success, error);
  }

  return (
    <>
      {pending > 0 ? (
        <Badge
          type="autosave"
          className="!bg-primary-100 !text-white my-2"
          label={`Upload in Progress`}
        />
      ) : (
        <AlertMessage {...alertProps} />
      )}
    </>
  );
};

export default CatalogUploadAlertMessage;
