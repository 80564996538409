// React-related imports
import { FC } from 'react';

// Components
import SvgIcon from '../elements/SvgIcon';
import { Button, Dropdown, Search } from '@GxT-Team/gxt-components';

// Types
import { TableActionBarProps } from './TableActionBar.types';

// Helpers
import { getDropdownOptions } from '../../helpers/utils';

const TableActionBar: FC<TableActionBarProps> = ({ handleSearch, buttons, searchTerm = '' }) => {
  return (
    <div className="flex w-full gap-2">
      {handleSearch && (
        <Search
          value={searchTerm}
          onChangeCallback={handleSearch}
          width="100%"
          className="h-full"
        />
      )}
      {buttons.map((button) => (
        <div key={button.id}>
          {button.type === 'select' ? (
            <Dropdown
              options={getDropdownOptions(button.options)}
              type={button.type}
              dropdownIcon
              disabled={button.disabled}
              initialValue={{ value: button.defaultValue }}
              renderOption={(item) => {
                return item.value.title;
              }}
              position="bottom"
              onSelect={(option) => button.onSelect && button.onSelect(option.value)}
              customStyles={
                button.customStyles
                  ? button.customStyles
                  : {
                      container: { height: '100%' },
                      button: { whiteSpace: 'nowrap', height: '100%' },
                      itemsWrapper: { zIndex: 100, width: 'fit-content' },
                    }
              }
              equalityFn={(a, b) => a?.value === b?.value}
              placeholder={button.placeholder}
            />
          ) : (
            <Button
              variant={button.variant}
              onClick={button.onClick}
              disabled={button.disabled}
              id={button.id}
              data-testid={button.dataTestId}
              className={`!h-full ${button.styles}`}>
              {button.title}
              {button.iconType && (
                <SvgIcon
                  data-testid={button.iconType || 'svg'}
                  iconType={button.iconType}
                  stroke={button.disabled ? 'var(--neutral-200, #9CA3AF)' : undefined}
                />
              )}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default TableActionBar;
