import { Button, CheckboxInput, SlideOver, Search } from '@GxT-Team/gxt-components';
import { useEffect, useState } from 'react';
import SvgIcon from '../elements/SvgIcon';
import { CheckboxSelectionSlideOverProps, Option } from './CheckboxSelectionSlideOver.types';

const CheckboxSelectionSlideOver = ({
  isOpen,
  handleClose,
  title = '',
  onSave,
  width = '28rem',
  options,
  selectedOptions = [],
  displayBackButton = false,
  multiple = true,
  isLoading = false,
}: CheckboxSelectionSlideOverProps) => {
  const [selectedData, setSelectedData] = useState<Option[]>(selectedOptions);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);

  const handleSearchChange = (term: string) => {
    setFilteredOptions(
      term.trim() === ''
        ? options
        : options.filter((option) => option.label.toLowerCase().includes(term.toLowerCase())),
    );
  };

  const handleCheckboxChange = (data: Option) => {
    if (!multiple) {
      setSelectedData([data]);
      return;
    }

    if (selectedData.find((d) => d.value === data.value)) {
      setSelectedData((prev) => prev.filter((item) => item.value !== data.value));
    } else {
      setSelectedData((prev) => [...prev, data]);
    }
  };

  useEffect(() => {
    setSelectedData(selectedOptions);
  }, [selectedOptions]);

  const handleSave = () => {
    onSave(selectedData);
    handleClose();
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <SlideOver isOpen={isOpen} onClose={handleClose} mountElementId="styled-wrapper" width={width}>
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        {displayBackButton && (
          <div
            className="flex items-center gap-1 text-[16px] font-[400] cursor-pointer"
            onClick={handleClose}>
            <SvgIcon iconType="arrow-back" />
            Back
          </div>
        )}
        {title}
      </SlideOver.Header>
      {isLoading && <p>Loading...</p>}
      <div
        className="flex flex-col space-y-5 max-h-[75vh] overflow-auto w-full scroll-smooth"
        style={{
          scrollbarWidth: 'thin',
        }}>
        {options.length > 5 && (
          <div className="flex w-full pr-2">
            <Search onChangeCallback={handleSearchChange} placeholder="Search..." />
          </div>
        )}
        {filteredOptions.map((option) => {
          return (
            <CheckboxInput
              key={option.value}
              $inputSize="xs"
              label={option.label}
              checked={!!selectedData.find((p) => p.value === option.value)}
              onClick={() => handleCheckboxChange(option)}
              role="checkbox"
              name={option.label}
            />
          );
        })}
      </div>
      <SlideOver.Footer>
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="secondary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default CheckboxSelectionSlideOver;
