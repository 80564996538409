import { Button, SlideOver } from '@GxT-Team/gxt-components';
import Comment from '../../../../components/Comment';
import { CommentsSlideOverProps } from '../../types';

const CommentsSlideOver = ({ isOpen, onClose, comments }: CommentsSlideOverProps) => {
  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      width="24rem"
      mountElementId="styled-wrapper"
      dataTestId="comments-slideover">
      <SlideOver.Header className="bg-primary-50 text-white">Comments</SlideOver.Header>
      {comments.map((comment) => (
        <Comment
          name={`${comment.firstName} ${comment.lastName}`}
          message={comment.comment}
          key={comment.commentNumber}
        />
      ))}
      <SlideOver.Footer className="text-white">
        <Button variant="secondary" onClick={onClose} className="ml-auto">
          Close
        </Button>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default CommentsSlideOver;
