import { Button } from '@GxT-Team/gxt-components';
import { FC } from 'react';
import SvgIcon from './elements/SvgIcon';

interface SuggestionNavigateButtonsProps {
  navigateMapping: (direction: 'previous' | 'next') => void;
  isPreviousAvailable: boolean;
  isNextAvailable: boolean;
  label?: string;
}

const SuggestionNavigateButtons: FC<SuggestionNavigateButtonsProps> = ({
  navigateMapping,
  isPreviousAvailable,
  isNextAvailable,
  label = (
    <>
      Navigate <br /> fields
    </>
  ),
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Button
        data-testid="previous-button"
        variant="secondary"
        circular={true}
        className="!p-[0.6rem]"
        disabled={!isPreviousAvailable}
        onClick={() => navigateMapping('previous')}>
        <SvgIcon iconType="navigate-back" />
      </Button>
      <h5 className="text-primary-200 font-normal">{label}</h5>
      <Button
        data-testid="next-button"
        variant="secondary"
        circular={true}
        className="!p-[0.6rem]"
        disabled={!isNextAvailable}
        onClick={() => navigateMapping('next')}>
        <SvgIcon iconType="navigate-next" />
      </Button>
    </div>
  );
};

export default SuggestionNavigateButtons;
