// React-related imports
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

// Contexts and Hooks
import { UserContext } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';
import { useDynamicStylingContext } from '../../contexts/DynamicStylingContext';
import { GeneralizationContext } from '../../contexts/GeneralizationContext';

// Components
import { Modal } from '../Modal';
import EditUserForm from '../user/EditUserForm';
import { Navbar as SideBarNav } from '@GxT-Team/gxt-components';

// Constants
import { FOOTER_ITEMS, NAVBAR_ITEMS } from '../../constants';

// Helpers
import { updatedNavItem } from '../../helpers/NavigationHelpers';
import ErrorBoundary from '../../pages/ErrorBoundary';

const Navbar = () => {
  const { userObj } = useContext(UserContext);

  const { permissions: userPermissions, logOutHandler } = useAuth();

  const [showModal, setShowModal] = useState(false);

  const { clientStyling } = useDynamicStylingContext();

  const { isFullScreen, setIsFullScreen } = useContext(GeneralizationContext);

  const isLinkActive = useCallback((path: string) => {
    const regex = new RegExp(`^${path.replace(/\*/g, '.*')}$`);
    return regex.test(window.location.pathname);
  }, []);

  const footerItems = useMemo(() => {
    return updatedNavItem({
      navItems: FOOTER_ITEMS,
      text: 'Logout',
      newKey: 'onClickHandler',
      newValue: logOutHandler,
    });
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      setIsFullScreen(false);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [setIsFullScreen]);

  return (
    <>
      <div className="flex">
        <div className="sticky top-0 h-full">
          {!isFullScreen && (
            <SideBarNav
              key={1}
              contentLinks={NAVBAR_ITEMS}
              footerLinks={footerItems}
              setShowAvatarMenu={setShowModal}
              showAvatarMenu={showModal}
              clientStyling={clientStyling}
              user={userObj}
              isLinkActive={isLinkActive}
            />
          )}
        </div>
        <div className={`w-full flex flex-col ${isFullScreen && 'h-screen'}`}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
      <Modal
        closeModal={() => setShowModal(false)}
        modalIsOpen={showModal}
        title="User Settings"
        size="sm">
        <EditUserForm
          closeModal={() => setShowModal(false)}
          user={{
            ...userObj,
            roles: userPermissions.map((role) => ({ name: role })),
            id: userObj.userId,
          }}
        />
      </Modal>
    </>
  );
};

export default Navbar;
