import { FC, useRef, useMemo } from 'react';
import { AutomappedDocumentsFilterType, IAutoMappedDocument } from '../types';
import { Table } from './table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import GeneralizedActions from './GeneralizedActions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { archiveMappingSession } from '../services/apiCalls';
import { TableHeader } from '@GxT-Team/gxt-components';

interface IAutomappedDocuments {
  autoMappingData: IAutoMappedDocument[] | never[];
  getAllAutomappedDocs: () => void;
  setFilters: (key: keyof AutomappedDocumentsFilterType, value: string) => void;
}

export const AutoMappedDocuments: FC<IAutomappedDocuments> = ({
  autoMappingData,
  getAllAutomappedDocs,
  setFilters,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleAuthor = (id: number) => {
    navigate(`/mapping-sessions/${id}`);
  };

  const handleMappingSessionDelete = (id: number) => {
    archiveMappingSession(id)
      .then((res) => {
        toast.success(res.data.message);
        getAllAutomappedDocs();
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  const handleColumnSort = (sort: string, column: string) => {
    setFilters('columnSort', column);
    setFilters('sortType', sort);
  };

  const columnHelper = createColumnHelper<IAutoMappedDocument>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: () => (
          <TableHeader ColumnName="id" Title="Id" handleColumnSort={handleColumnSort} />
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('title', {
        header: () => (
          <TableHeader ColumnName="title" Title="Title" handleColumnSort={handleColumnSort} />
        ),
        cell: (info) => info.getValue(),
        size: 700,
      }),
      columnHelper.accessor('status', {
        header: () => (
          <TableHeader ColumnName="status" Title="Status" handleColumnSort={handleColumnSort} />
        ),
        cell: (info) => info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1),
      }),
      columnHelper.accessor('createdBy', {
        header: () => (
          <TableHeader
            ColumnName="firstName"
            Title="Mapped By"
            handleColumnSort={handleColumnSort}
          />
        ),
        cell: (info) => {
          const createdBy = info.row.original.createdBy;
          const fullName = createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '';
          return fullName;
        },
      }),
      columnHelper.accessor('id', {
        header: () => 'Action',
        cell: (info) => {
          return (
            <GeneralizedActions
              isDeleteActionVisible={true}
              isVerifyActionVisible={false}
              handleDeleteItem={() => handleMappingSessionDelete(info.getValue())}
              isAuthorVisible={true}
              handleAuthor={() => handleAuthor(info.getValue())}
              type="automapping"
              isEditActionVisible={false}
              id={info.getValue()}
            />
          );
        },
        size: 10,
        maxSize: 10,
      }),
    ],
    [],
  );

  return (
    <div
      className="mt-0 overflow-y-auto overflow-hidden my-4"
      style={{ height: '60vh' }}
      ref={containerRef}>
      <Table data={autoMappingData} columns={columns} />
    </div>
  );
};
