import { Modal } from '@GxT-Team/gxt-components';
import SvgIcon from '../../../../components/elements/SvgIcon';

type NewSuggestionModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const NewSuggestionModal = ({ isOpen, handleClose }: NewSuggestionModalProps) => {
  const newSuggestionInfoList = [
    'Select target cell for new suggestion request.',
    'Select another target reference cell as a reference for suggestions.',
    'Select source document(s) for new suggestions.',
    'Click run.',
    'Browse new suggestion in the Search bar.',
    'Continue authoring with new suggestions.',
  ];
  return (
    <Modal isOpen={isOpen} maxWidth="43.625rem" onClose={handleClose}>
      <Modal.ModalHeader>
        <Modal.ModalHeaderHeading>
          <div className="flex justify-between items-center">
            <h3>Request New Suggestion(s)</h3>
            <div className="cursor-pointer w-fit">
              <SvgIcon
                iconType="close"
                width={25}
                height={25}
                onClick={handleClose}
                data-testid="close-icon"
              />
            </div>
          </div>
        </Modal.ModalHeaderHeading>
        <Modal.ModalHeaderBody>
          <ol className="list-decimal px-5 text-neutral-400 pt-4">
            {newSuggestionInfoList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ol>
          <p className="text-neutral-400 text-sm mt-2">
            Continue authoring while suggestions are processing by toggling off, turn back on when
            it’s done.
          </p>
        </Modal.ModalHeaderBody>
      </Modal.ModalHeader>
    </Modal>
  );
};

export default NewSuggestionModal;
