import {
  AIResponse,
  GeneralizationSuggestion,
  ObjectValue,
  ISuggestionMapper,
  EditorRef,
  CatalogSourceNodeObject,
  GeneralizationSuggestionWithColor,
  KeyboardEventWithShift,
  CatalogNode,
  EditorReference,
  GeneralizationDocument,
  HighlightOptions,
  GeneralizationSuggestionMap,
  SizeUnit,
  ICategory,
  IGroupingVariables,
  MappingTypeEnum,
  SuggestionData,
} from '../types';
import _ from 'lodash';
import {
  AUTHORING_COLORS,
  LIGHT_GRAY_COLOR,
  LIGHT_GREEN_COLOR,
  LIGHT_RED_COLOR,
  mappingByType,
  suggestionStatus,
  TEMPLATE_TRAINING_COLORS,
} from '../constants';
import { RefObject } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { colorSVGPaths, getActiveSvgPin, getSvgPinById } from './imageTaggingHelper';
import { toast } from 'react-toastify';

export const getElement = (editorRef: RefObject<EditorRef>, nodeId: string): HTMLElement | null => {
  const queryElement = (nodeId: string) =>
    editorRef?.current?.getDoc()?.querySelector(`[data-nodeid='${nodeId}']`);

  const modifiedNodeId = nodeId?.replace('.html[0].body[1]', '') ?? '';
  const element =
    (queryElement(nodeId) as HTMLElement) ?? (queryElement(modifiedNodeId) as HTMLElement);

  return element ?? null;
};

export const getElements = (
  editorRef: RefObject<EditorRef>,
  nodeIds: string[],
): (HTMLElement | null)[] => {
  return nodeIds.map((nodeId) => getElement(editorRef, nodeId));
};

const replaceChildNodeTextContent = (node: Node, content: string) => {
  if (node.nodeType === Node.TEXT_NODE) {
    node.textContent = content;
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    const childNodes = node.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      replaceChildNodeTextContent(childNodes[i], content);
    }
  }
};

export const clearHighlightedElements = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const values = doc.querySelectorAll<HTMLElement>('[style^=color], [color]');
  Array.from(values).forEach((cell) => {
    replaceChildNodeTextContent(cell, '');
    cell.removeAttribute('color');
    cell.innerHTML += '&nbsp;';
  });
  return doc?.body?.outerHTML;
};

export const changeSuggestedTargetNodeText = (
  suggestionNodeValues: Array<[string, HTMLElement]>,
  clearText: boolean,
) => {
  suggestionNodeValues?.forEach(([originalHTML, element]) => {
    if (clearText) {
      replaceChildNodeTextContent(element, '-');
    } else {
      element.innerHTML = originalHTML || '';
    }
  });
};

export const getElementsByQuery = (editor: RefObject<HTMLIFrameElement>, query: string) =>
  editor?.current?.contentWindow?.document.querySelectorAll(query);

export const getMappingValues = (doc: Document, nodeId: string): string => {
  const mappingNodes = doc.querySelectorAll(`[data-nodeid="${nodeId}"]`);
  if (mappingNodes) {
    return (
      Array.from(mappingNodes)
        ?.map((mappingNode) => `value=${(mappingNode as HTMLElement).innerText}`)
        ?.join(', ') || ''
    );
  }
  return '';
};

const suggestionMappers: Record<ISuggestionMapper['type'], ISuggestionMapper> = {
  author: {
    type: 'author',
    color: 'lightpink',
    label: 'Author',
  },
  verifier: {
    type: 'verifier',
    color: 'lightgreen',
    label: 'Document Verifier',
  },
  default: {
    type: 'default',
    color: 'lightblue',
    label: 'AI',
  },
};

export const getValidSuggestionMapperType = (
  suggestionMapperType: string | undefined,
): ISuggestionMapper['type'] =>
  [mappingByType.author, mappingByType.verifier].includes(
    suggestionMapperType as ISuggestionMapper['type'],
  )
    ? (suggestionMapperType as ISuggestionMapper['type'])
    : 'default';

export const getMappingColor = (suggestionMapperType: ISuggestionMapper['type']) =>
  suggestionMappers[getValidSuggestionMapperType(suggestionMapperType)].color;

export const getMapper = (suggestionMapperType: ISuggestionMapper['type']) =>
  suggestionMappers[getValidSuggestionMapperType(suggestionMapperType)].label;

export const scrollIntoViewIfNeeded = (element: HTMLElement, center = true) => {
  const rect = element.getBoundingClientRect();
  const elementDocument = element.ownerDocument;
  const elementWindow = element.ownerDocument.defaultView;

  if (elementDocument && elementWindow) {
    const viewHeight = Math.max(
      elementDocument.documentElement.clientHeight,
      elementWindow.innerHeight || 0,
    );

    if (rect.top < 0 || rect.bottom > viewHeight) {
      if (element.scrollIntoView) {
        element.scrollIntoView({
          behavior: 'auto',
          block: center ? 'center' : 'nearest',
        });
      } else {
        elementWindow.scrollTo({
          top: rect.top + elementWindow.scrollY - viewHeight / 2,
          behavior: 'auto',
        });
      }
    }
  }
};

export const highlightSuggestion = (options: HighlightOptions) => {
  const { element, color = '', isScroll = true, mapperType = '' } = options;

  if (!element) {
    return;
  }

  if (isScroll) scrollIntoViewIfNeeded(element);

  element.style.backgroundColor = color;
  if (color) {
    element.setAttribute('data-highlighted', 'true');
  } else {
    element.removeAttribute('data-highlighted');
  }
  if (element.innerText.trim() !== '' && !!mapperType) {
    element.setAttribute('title', `Mapped by ${mapperType}`);
  } else {
    element.removeAttribute('title');
  }
};

const findObjectByValue = (
  objects: ObjectValue[],
  key: string,
  value: string,
): ObjectValue | undefined => {
  return objects.find((obj) => _.unescape(obj[key]?.value) === _.unescape(value));
};

const getUpdatedAiResponseObject = (
  aiResponse: AIResponse,
  suggestion: GeneralizationSuggestion,
): AIResponse => {
  const existingObj = findObjectByValue(
    aiResponse[suggestion.targetNodeId],
    suggestion.sourceNodeId,
    suggestion.targetValue,
  );

  if (existingObj) {
    const updatedObj = {
      [suggestion.sourceNodeId]: {
        value: suggestion.targetValue,
        sourceFileId: suggestion.sourceFileId,
      },
    };

    const updatedAiResponse = {
      ...aiResponse,
      [suggestion.targetNodeId]: aiResponse[suggestion.targetNodeId].map((obj) =>
        obj === existingObj ? updatedObj : obj,
      ),
    };

    return updatedAiResponse;
  } else {
    const newAiResponse = {
      ...aiResponse,
      [suggestion.targetNodeId]: [
        ...(aiResponse[suggestion.targetNodeId] ?? []),
        {
          [suggestion.sourceNodeId]: {
            value: suggestion.targetValue,
            sourceFileId: suggestion.sourceFileId,
          },
        },
      ],
    };

    return newAiResponse;
  }
};

export const getUpdatedAiResponse = (
  aiResponse: AIResponse,
  acceptedSuggestions: GeneralizationSuggestion[],
): AIResponse => {
  const validSuggestions = filterValidSuggestions(acceptedSuggestions);

  const updatedAiResponseObjects = validSuggestions.map((suggestion: GeneralizationSuggestion) =>
    aiResponse[suggestion.targetNodeId]
      ? getUpdatedAiResponseObject(aiResponse, suggestion)
      : {
          ...aiResponse,
          [suggestion.targetNodeId]: [
            {
              [suggestion.sourceNodeId]: {
                value: suggestion.targetValue,
                sourceFileId: suggestion.sourceFileId,
                positionX: suggestion.positionX,
                positionY: suggestion.positionY,
              },
            },
          ],
        },
  );

  const newAiResponse = _.merge({}, aiResponse, ...updatedAiResponseObjects);

  return newAiResponse;
};

const filterValidSuggestions = (
  acceptedSuggestions: GeneralizationSuggestion[],
): GeneralizationSuggestion[] => {
  return acceptedSuggestions.filter((suggestion) => suggestion.sourceNodeId != null);
};

export const getSuggestionByTargetNodeId = (
  suggestions: GeneralizationSuggestion[],
  targetNodeId: string,
) => {
  return suggestions
    .sort((a, b) => (b.previousSuggestionId || 0) - (a.previousSuggestionId || 0))
    .find((suggestions) => suggestions.targetNodeId === targetNodeId);
};

export const getCatalogId = (editorRef: any, id: string): string | undefined | null => {
  if (!id) return id;

  const element: Element | null = getElement(editorRef, id);
  return element?.getAttribute('data-docid');
};

export const getCatalogWithSourceIdsFromAIResponse = (
  aiRes: AIResponse,
): CatalogSourceNodeObject => {
  const output: CatalogSourceNodeObject = {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_targetNodeId, values] of Object.entries(aiRes)) {
    _.flatMap(values).forEach((sourceNode: any) => {
      const catalogId = _.values(sourceNode)[0].sourceFileId;
      if (catalogId) {
        if (!output[catalogId]) {
          output[catalogId] = [];
        }
        output[catalogId].push(..._.keys(sourceNode));
      }
    });
  }
  return output;
};

export const getCatalogWithSourceIdsFromSuggestions = (
  acceptedSuggestions: GeneralizationSuggestion[],
): CatalogSourceNodeObject => {
  const output: CatalogSourceNodeObject = {};

  acceptedSuggestions.forEach((suggestion) => {
    const catalogId = suggestion.sourceFileId;
    if (catalogId) {
      if (!output[catalogId]) {
        output[catalogId] = [];
      }
      output[catalogId].push(suggestion.sourceNodeId);
    }
  });
  return output;
};

export const getCellElement = (
  clickedElement: Node,
  editorBody: Node,
): HTMLTableCellElement | null => {
  let cellElement: Node | null = null;
  let currentNode: Node | null = clickedElement;

  while (currentNode && currentNode !== editorBody) {
    if (currentNode.nodeName === 'TD' || currentNode.nodeName === 'TH') {
      cellElement = currentNode;
      break;
    } else {
      currentNode = currentNode.parentNode;
    }
  }

  return cellElement as HTMLTableCellElement | null;
};

export const findMatchingNodeId = (htmlElement: HTMLElement, nodeIds: string[]): string | null => {
  const nodeIdSet = new Set(nodeIds);
  const nodes = getAllNodesInElement(htmlElement);
  const foundNode = nodes?.find((node) => nodeIdSet.has(node.getAttribute('data-nodeid') || ''));
  return foundNode ? foundNode.getAttribute('data-nodeid') : null;
};

export const getAllNodesInElement = (htmlElement: HTMLElement | null, withParent = true) => {
  if (htmlElement === null) return htmlElement;
  const selector = '[data-nodeid]';
  const nodes = Array.from(htmlElement.querySelectorAll(selector));
  withParent && nodes.unshift(htmlElement);
  return nodes;
};

export const getAllNodesIdsInElement = (htmlElement: HTMLElement | null) => {
  const nodes = getAllNodesInElement(htmlElement) || [];
  return nodes.reduce((acc, node) => {
    if (node.hasAttribute('data-nodeid')) {
      acc.push(node.getAttribute('data-nodeid') as string);
    }
    return acc;
  }, [] as string[]);
};

export const addColorToObjects = (
  objects: GeneralizationSuggestion[],
  defaultColor: string,
): GeneralizationSuggestionWithColor[] => {
  const assignColor = (obj: GeneralizationSuggestion) => {
    if (obj.status === suggestionStatus.APPROVED) {
      return LIGHT_GREEN_COLOR;
    }
    if (obj.status === suggestionStatus.REJECTED) {
      return LIGHT_RED_COLOR;
    }
    return defaultColor;
  };

  return objects.map((obj) => ({
    ...obj,
    color: assignColor(obj),
  }));
};

export const updateElementBackgroundColor = (element: HTMLElement | null, color: string) => {
  if (element) {
    element.style.backgroundColor = color;
  }
};

const addNumberToHexCode = (hexCode: string, number: number) => {
  const hex = hexCode.replace('#', '');
  const decimal = parseInt(hex, 16);
  const sum = decimal + number;
  const resultHex = sum.toString(16);
  const resultHexCode = `#${resultHex}`;
  return resultHexCode;
};

export const generateRandomColor = (existingColorsLength: number): string => {
  const minBrightness = 128;
  const colorPalettes = [
    '#94C3DB',
    '#B7D8B0',
    '#CDA2E2',
    '#F2E6A9',
    '#FFC9A5',
    '#6A8CC2',
    '#98B87E',
    '#AC8BCB',
    '#F4D977',
    '#E5957B',
    '#7396C0',
    '#A2CE7F',
    '#C781B4',
    '#FCD66E',
    '#EC8369',
  ];

  let color: string | null = null;
  const colorIndex: number = existingColorsLength % colorPalettes.length;

  while (!color || Object.values<string>(AUTHORING_COLORS).includes(color.toLowerCase())) {
    const selectedColor = addNumberToHexCode(
      colorPalettes[colorIndex],
      existingColorsLength + Math.random() * 256,
    );
    const red = parseInt(selectedColor.substring(1, 3), 16);
    const green = parseInt(selectedColor.substring(3, 5), 16);
    const blue = parseInt(selectedColor.substring(5, 7), 16);

    const brightness = 0.299 * red + 0.587 * green + 0.114 * blue;

    if (brightness >= minBrightness) {
      color = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue
        .toString(16)
        .padStart(2, '0')}`;
    }
  }

  return color;
};

export const deepCopyDocument = (source: Document) => {
  if (!source) return;
  const target = document.implementation.createHTMLDocument('');
  target.documentElement.replaceWith(source.documentElement.cloneNode(true));
  return target;
};

export const removeChildNodeTextContent = (node: Node): string => {
  const clone = node.cloneNode(true) as HTMLElement;
  while (clone.firstElementChild) {
    clone.removeChild(clone.firstElementChild);
  }
  const textContent = _.unescape(clone.textContent?.trim()) || '';
  return textContent;
};

export const handleAcceptButtonClick = (
  e: KeyboardEventWithShift,
  acceptButtonRef: React.RefObject<HTMLButtonElement>,
): void => {
  if (acceptButtonRef.current) {
    e.preventDefault();
    e.stopPropagation();
    acceptButtonRef.current.click();
  }
};

export const handleEnterKeyPress = (
  e: KeyboardEventWithShift,
  acceptButtonRef: React.RefObject<HTMLButtonElement>,
  editor?: any,
): void => {
  switch (true) {
    case (e.ctrlKey || e.metaKey) && e.key === 'Enter':
      editor?.execCommand(
        'mceInsertContent',
        false,
        `<p data-new-paragraph="true" >&lt;New Line&gt;</p>`,
      );
      e.preventDefault();
      break;

    case e.shiftKey && e.key === 'Enter':
      handleAcceptButtonClick(e, acceptButtonRef);
      break;
  }
};

export const extractTextFromNode = (node: any): string => {
  if (typeof node === 'string') {
    return node;
  }
  if (typeof node === 'number') {
    return node.toString();
  }
  if (Array.isArray(node)) {
    return node.map(extractTextFromNode)[0];
  }
  if (typeof node === 'object' && node.props && node.props.children) {
    return extractTextFromNode(node.props.children);
  }
  return '';
};

export const removeBackgroundColor = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const elements = doc.querySelectorAll('[style*="background-color"]');

  elements.forEach((element) => {
    const tagName = element.tagName.toLowerCase();
    const isSpanWithTagAttribute =
      tagName === 'span' && (element as HTMLElement).getAttribute('data-highlight') === 'partial';

    if (!isSpanWithTagAttribute) {
      (element as HTMLElement).style.removeProperty('background-color');
    }
  });
  return doc.body.innerHTML;
};

export const hexToRGB = (hex: string) => {
  let r = 0,
    g = 0,
    b = 0;

  hex = hex.substring(1);

  if (hex.length === 3) {
    r = parseInt(hex.substring(0, 1) + hex.substring(0, 1), 16);
    g = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16);
    b = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const updateAllSpanBackgroundColors = (
  spans: NodeListOf<HTMLElement>,
  sourceColor: string = AUTHORING_COLORS.NARRATIVE_SELECTION,
  targetColor: string = AUTHORING_COLORS.NARRATIVE_SELECTION,
) => {
  if (sourceColor.startsWith('#')) {
    sourceColor = hexToRGB(sourceColor);
  }

  spans.forEach((span: HTMLElement) => {
    const computedStyle = window.getComputedStyle(span);
    const backgroundColor = computedStyle.backgroundColor;

    if (backgroundColor === sourceColor) {
      span.style.backgroundColor = targetColor;
    }
  });
};

export const findParentWithDataNodeId = (element: HTMLElement | null): HTMLElement | null => {
  if (!element) {
    return null;
  }

  if (element.getAttribute('data-nodeid')) {
    return element;
  }

  return findParentWithDataNodeId(element.parentElement);
};

export const getNodeIdFromAttribute = (element: HTMLElement | null): string => {
  if (!element) return '';
  const attribute = element.getAttribute('data-nodeid');
  if (!attribute) return '';
  const parts = attribute.split('_');
  return parts.length > 1 ? parts[1] : attribute;
};

export const createCatalogNode = (
  element: HTMLElement,
  generatedDocument: GeneralizationDocument,
): CatalogNode | null => {
  const potentialNode: Partial<CatalogNode> = {};

  const parentNode = findParentWithDataNodeId(element.parentElement);
  const parser = new DOMParser();
  const doc = parser.parseFromString(generatedDocument.html, 'text/html');
  if (element.parentElement?.tagName === 'BODY') {
    potentialNode.parentNodeId = getNodeIdFromAttribute(doc.body);
  } else if (parentNode) {
    potentialNode.parentNodeId = getNodeIdFromAttribute(parentNode);
  }

  if (element.textContent) {
    potentialNode.text = element.textContent;
  }

  const tag = element.tagName.toLowerCase();
  if (tag) {
    potentialNode.tag = tag;
    if (['tr', 'thead', 'table', 'tfoot', 'tbody'].includes(tag)) {
      potentialNode.text = '';
    }
  }

  const dataNodeId = getNodeIdFromAttribute(element);
  if (dataNodeId) {
    potentialNode.dataNodeId = dataNodeId;
    potentialNode.attrNodeId = dataNodeId;
  }

  const siblingIdx = element.getAttribute('data-nodeorder');
  if (siblingIdx !== null) {
    potentialNode.siblingIdx = siblingIdx;
  }

  const dataNodeOrder = element.getAttribute('data-nodeorder');
  if (dataNodeOrder && dataNodeId) {
    potentialNode.attr = {
      'data-nodeid': dataNodeId,
      'data-docid': generatedDocument.id.toString(),
      'data-nodeorder': dataNodeOrder,
      'data-manuallycreated': 'true',
    };
    Array.from(element.attributes).forEach(({ name, value }) => {
      if (potentialNode.attr && !name.includes('data-') && name !== 'style') {
        potentialNode.attr[name] = value;
      }
    });
  }

  if (
    potentialNode.parentNodeId &&
    potentialNode.tag &&
    potentialNode.dataNodeId &&
    potentialNode.siblingIdx &&
    potentialNode.attrNodeId &&
    potentialNode.attr
  ) {
    return potentialNode as CatalogNode;
  }

  return null;
};

const calculateMedianOrder = (prevOrder: number, nextOrder: number) => {
  if (nextOrder === 0) return prevOrder + 1;
  if (prevOrder === 0 && nextOrder === 0) return 1;
  return (prevOrder + nextOrder) / 2;
};

const removeAllStyles = (element: HTMLElement | null) => {
  if (!element) return;
  if (element.nodeType === Node.ELEMENT_NODE) {
    element.removeAttribute('style');
    const elementsWithStyles = element.querySelectorAll<HTMLElement>('[style]');
    elementsWithStyles.forEach(
      (el: HTMLElement) => el.tagName !== 'SPAN' && el.removeAttribute('style'),
    );
  }
};

export const applyColorForNewCells = (
  editor1Ref: EditorReference,
  newCatNodes: CatalogNode[],
  suggestionsToSave: SuggestionData[],
) => {
  const editor1Doc = editor1Ref.current.getDoc().body;
  if (!editor1Ref.current || !editor1Doc) return;

  const newNodesList = newCatNodes.filter((newNode) =>
    suggestionsToSave.some((suggestion) => suggestion.targetNodeId === newNode.dataNodeId),
  );

  if (!newNodesList.length) return;

  const nodes = editor1Doc.querySelectorAll<HTMLElement>(
    newNodesList.map((node) => `[data-nodeid="${node.dataNodeId}"]`).join(', '),
  );

  nodes.forEach((node) => {
    node.style.backgroundColor = LIGHT_GRAY_COLOR;
  });
};

export const setAttributesAndOrder = (element: HTMLElement, docId: string, forceSet = false) => {
  if (forceSet || !element.getAttribute('data-nodeid')) {
    if (element.nodeType === Node.ELEMENT_NODE) {
      element.setAttribute('data-nodeid', uuidv4());
    }
  }

  if (forceSet || !element.getAttribute('data-docid')) {
    if (element.nodeType === Node.ELEMENT_NODE) {
      element.setAttribute('data-docid', docId);
    }
  }

  const prevElement = element.previousElementSibling as HTMLElement | null;
  const nextElement = element.nextElementSibling as HTMLElement | null;

  const prevOrder = parseFloat(prevElement?.getAttribute('data-nodeorder') ?? '0');
  const nextOrder = parseFloat(nextElement?.getAttribute('data-nodeorder') ?? '0');
  const medianOrder = calculateMedianOrder(prevOrder, nextOrder);

  if (forceSet || !element.getAttribute('data-nodeorder')) {
    if (element.nodeType === Node.ELEMENT_NODE) {
      element.setAttribute('data-nodeorder', String(medianOrder));
    }
  }

  removeAllStyles(element);
};

export const getNewCatalogNodes = (
  nodes: NodeListOf<Element>,
  generatedDocument: GeneralizationDocument,
): CatalogNode[] => {
  const newCatalogNodes: CatalogNode[] = [];
  nodes.forEach((node) => {
    const newCatalogNode = createCatalogNode(node as HTMLElement, generatedDocument);
    if (newCatalogNode !== null) newCatalogNodes.push(newCatalogNode);
  });
  return newCatalogNodes;
};

export const newCatalogNodes = (
  editor1Ref: EditorReference,
  generatedDocument: GeneralizationDocument,
) => {
  if (!editor1Ref.current) {
    return;
  }

  const editor1Doc = editor1Ref.current.getDoc().body;
  if (!editor1Doc) return;

  const nodes = editor1Doc.querySelectorAll<HTMLElement>(
    ':not([data-nodeid]):not(br):not(span[data-highlight]):not(tbody):not([data-comment]), [data-newnode]',
  );

  nodes.forEach((node) => {
    setAttributesAndOrder(node, generatedDocument.id.toString());
    node.removeAttribute('data-newnode');
  });

  return getNewCatalogNodes(nodes, generatedDocument);
};

export const fixTableCells = (htmlContent: string): string => {
  if (!htmlContent) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const cells = doc.querySelectorAll('td, th');

  for (const cell of cells) {
    const row = cell.parentElement as HTMLTableRowElement;
    if (
      cell.tagName.toLowerCase() === 'td' &&
      row.parentElement?.tagName.toLowerCase() === 'thead'
    ) {
      const newCell = createCell(doc, 'th', cell);
      row.replaceChild(newCell, cell);
    } else if (
      cell.tagName.toLowerCase() === 'th' &&
      row.parentElement?.tagName.toLowerCase() === 'tbody'
    ) {
      const newCell = createCell(doc, 'td', cell);
      row.replaceChild(newCell, cell);
    }
  }

  return doc.documentElement.outerHTML;
};

export const replaceTocHeadingsWithH2 = (htmlString: string): string => {
  if (!htmlString) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const h1Elements = doc.querySelectorAll('h1');

  h1Elements.forEach((h1Element) => {
    const tocLink = h1Element.querySelector('a[name^="_Toc"]');
    if (tocLink) {
      const h2Element = doc.createElement('h2');
      h2Element.innerHTML = h1Element.innerHTML;
      h1Element.replaceWith(h2Element);
    }
  });

  return doc.documentElement.outerHTML;
};

export const insertCodeInBlankCells = (
  htmlBody: string,
  code = '&nbsp;',
  width: SizeUnit = '50px',
  height: SizeUnit = '30px',
): string => {
  if (!htmlBody) return '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlBody, 'text/html');

  const tdElements = doc.querySelectorAll('td');

  tdElements.forEach((tdElement: HTMLElement) => {
    if (!tdElement.textContent?.trim()) {
      tdElement.innerHTML = code;
      tdElement.style.width = width;
      tdElement.style.height = height;
    }
  });

  return doc.body.innerHTML;
};

export const formatTableCells = (htmlBody: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlBody, 'text/html');
  const cells = doc.querySelectorAll<HTMLElement>('td, th');
  const htmlHeadings = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
  const inlineTags = ['STRONG', 'EM', 'SUB', 'SUP', 'SPAN', 'A', 'IMG', 'SPAN'];

  let isHeading = false;
  let isTitle = false;
  cells.forEach((cell) => {
    const content = [...(cell.childNodes as NodeListOf<HTMLElement>)].reduce(
      (acc, node, index, nodes) => {
        if (htmlHeadings.includes(node.nodeName)) {
          isHeading = true;
        }
        if (node.classList?.contains('MsoTitle')) {
          isTitle = true;
        }
        if (isHeading) return '';
        if (inlineTags.includes(node.nodeName)) {
          return `${acc}${(node as Element).outerHTML || ''}`;
        }
        if (node.nodeName !== '#text' && node.nodeName !== 'BR') {
          return `${acc}${index === 0 ? '' : '<br>'}${node.textContent || ''}`;
        }
        if (node.nodeName === 'BR') {
          return `${acc}${index === nodes.length - 1 ? '' : '<br>'}`;
        }
        return `${acc}${node.nodeValue || ''}`;
      },
      '',
    );
    if (!isHeading)
      cell.innerHTML = `<p class=${
        isTitle ? 'MsoTitle' : '""'
      } style="margin: 3pt 0cm;">${content.trim()}</p>`;
    isHeading = false;
    isTitle = false;
  });
  return doc.body.innerHTML;
};

const createCell = (doc: Document, tagName: string, cell: Element): HTMLElement => {
  const newCell = doc.createElement(tagName);

  for (const attr of Array.from(cell.attributes)) {
    newCell.setAttribute(attr.name, attr.value);
  }

  newCell.innerHTML = cell.innerHTML;
  return newCell;
};

export const getCellNodeId = (nodeId: string, editorRef: RefObject<EditorRef>): string | null => {
  const element = getElement(editorRef, nodeId);

  if (!element || !editorRef?.current?.getBody()) return null;

  const cellElement = getCellElement(element, editorRef?.current?.getBody() as Node) || element;
  const nodeAttribute = cellElement?.getAttribute('data-nodeid');

  return nodeAttribute || null;
};

export const getCellNodeById = (
  nodeId: string,
  editorRef: RefObject<EditorRef>,
): HTMLElement | null => {
  const element = getElement(editorRef, nodeId);

  if (!element || !editorRef?.current?.getBody()) return null;

  const tdElement = getCellElement(element, editorRef?.current?.getBody() as Node);

  return tdElement || element;
};

export const getMergedEditsIdsWithAiResponseIds = (
  aiResponseIds: string[] = [],
  acceptedSuggestions: GeneralizationSuggestion[] = [],
  editor1Ref: RefObject<EditorRef>,
) => {
  const uniqueNodeIds = new Set<string>(aiResponseIds.filter((id) => id !== ''));
  acceptedSuggestions.forEach((acceptedSuggestion) => {
    const cellNodeId = getCellNodeId(acceptedSuggestion.targetNodeId, editor1Ref);
    if (cellNodeId) {
      uniqueNodeIds.add(cellNodeId);
    }
  });
  return Array.from(uniqueNodeIds);
};

export const extractNodeIdsFromAIResponse = (
  aiResponse: AIResponse,
  editorRef: RefObject<EditorRef>,
): Array<string> => {
  return Object.keys(aiResponse).reduce((nodeIds, id) => {
    const nodeId = getCellNodeId(id, editorRef);
    if (nodeId) nodeIds.push(nodeId);
    return nodeIds;
  }, [] as Array<string>);
};

export const isNodeHighlighted = (el: HTMLElement | null): boolean => {
  if (!el) return true;
  return el.getAttribute('data-highlighted') ? true : false;
};

export const isCellEmpty = (element: HTMLElement | null) => {
  if (!element) return true;
  const innerText = element.innerText.trim();
  const isEmptySymbol = ['_', '-', ''].includes(innerText);

  return isEmptySymbol;
};

export const removeHighlightSuggestions = (editorRef: RefObject<EditorRef>, nodeIds: string[]) => {
  nodeIds?.forEach((nodeId) => {
    const element = getElement(editorRef, nodeId);
    if (element) {
      highlightSuggestion({ element });
    }
  });
};

export const getNodeIdAndValues = (nodeIds: string[], editorRef: RefObject<EditorRef>) => {
  const result: Array<[string, HTMLElement]> = [];
  nodeIds.forEach((nodeId) => {
    const el = getElement(editorRef, nodeId);
    el && result.push([el.innerHTML, el]);
  });
  return result;
};

export const highlightMatchingElements = (
  editor1Ref: RefObject<EditorRef>,
  editor2Ref: RefObject<EditorRef>,
  currentT1NodeIds: string[],
): void => {
  if (currentT1NodeIds.length !== 1) return;

  const TARGET_COLOR = 'rgb(255, 195, 106)';
  const SET_COLOR = AUTHORING_COLORS.NARRATIVE_SELECTION;

  const element = getElement(editor1Ref, currentT1NodeIds[0]);

  if (!element) return;

  const elements = element.querySelectorAll('span[data-selectionid][data-sourceid]');
  elements.forEach((element: Element) => {
    const bgColor = window.getComputedStyle(element, null).getPropertyValue('background-color');

    if (bgColor !== TARGET_COLOR) return;

    const sourceNodeId = element.getAttribute('data-sourceid');
    const selectionId = element.getAttribute('data-selectionid');

    if (!sourceNodeId) return;

    const sourceElement = getElement(editor2Ref, sourceNodeId);
    if (!sourceElement) return;
    const sourceSpan = (sourceElement as HTMLElement).querySelector(
      `[data-selectionid='${selectionId}']`,
    );
    if (sourceSpan) {
      (sourceSpan as HTMLElement).style.setProperty('background-color', SET_COLOR);

      scrollIntoViewIfNeeded(sourceSpan as HTMLElement);
    }
  });
};

export const filteredAiResponse = (aiResponse: AIResponse, catalogId: number) => {
  const filteredMap: AIResponse = {};
  Object.entries(aiResponse)?.forEach(([key, value]) => {
    const filteredArray = value.filter((item) => {
      const sourceFileId = Object.values(item)[0].sourceFileId;
      return +sourceFileId === catalogId;
    });
    if (filteredArray.length > 0) {
      filteredMap[key] = filteredArray;
    }
  });
  return filteredMap;
};

export const getAllCells = (htmlString: string) => {
  if (htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const allNodes: HTMLElement[] = Array.from(
      doc?.querySelectorAll('td, :not(td) p, th, :not(th) p') || [],
    );

    const ids = allNodes.reduce((accumulator: string[], node: HTMLElement) => {
      const nodeId = node.getAttribute('data-nodeid');
      if (nodeId) {
        accumulator.push(nodeId);
      }
      return accumulator;
    }, []);

    return ids;
  }
  return [];
};

export const setTargetElement = (
  editorRef: RefObject<EditorRef>,
  text = '',
  nodeId: string | null = null,
) => {
  if (!nodeId) return;
  const element = getElement(editorRef, nodeId);
  if (element) {
    if (element.tagName === 'IMG') {
      element.setAttribute('src', text);
      element.style.maxWidth = '500px';
      element.style.maxHeight = '300px';
      element.style.width = 'auto';
      element.style.height = 'auto';
    } else {
      element.innerHTML = text;
    }
    element.setAttribute('data-nodeid', nodeId);
  }
};

export const getSuggestionColor = (
  nodeId: string,
  acceptedSuggestions: GeneralizationSuggestion[],
  aiResponse: AIResponse,
  editor1Ref: RefObject<EditorRef>,
) => {
  const el = getElement(editor1Ref, nodeId);
  const ids = getAllNodesIdsInElement(el);
  const accepted = acceptedSuggestions?.find((s) => ids.includes(s.targetNodeId));
  if (accepted) {
    return accepted.mapperType === mappingByType.ai ? 'lightgreen' : 'lightyellow';
  } else if (aiResponse && ids.find((id) => aiResponse[id])) return 'lightblue';
};

export const highlightSuggestions = (
  nodeEditorRef: RefObject<EditorRef>,
  editor1Ref: RefObject<EditorRef>,
  acceptedSuggestions: GeneralizationSuggestion[],
  aiResponse: AIResponse,
  nodeIds: string[],
  color: string | null = null,
  isScroll = true,
) => {
  nodeIds?.forEach((nodeId) => {
    const element = getElement(nodeEditorRef, nodeId);
    if (element) {
      const highlightColor =
        color || getSuggestionColor(nodeId, acceptedSuggestions, aiResponse, editor1Ref);
      highlightSuggestion({
        element,
        color: highlightColor,
        isScroll,
      });
    }
  });
};

export const handlePinElement = (
  editor2Ref: RefObject<EditorRef>,
  currentT1NodeIds: string[],
): void => {
  if (currentT1NodeIds.length === 1 && editor2Ref?.current?.getDoc()) {
    const pinElement = getSvgPinById(editor2Ref?.current?.getDoc(), currentT1NodeIds[0]);
    const activePinElement = getActiveSvgPin(editor2Ref.current.getDoc());

    if (activePinElement) {
      const defaultShades = ['#C0392B', '#BDC3C7', '#7F8C8D', '#E74C3C'];
      activePinElement.setAttribute('data-marker', 'saved');
      colorSVGPaths(activePinElement, defaultShades, false);
    }
    if (pinElement) {
      const blueShades = ['#0074D9', '#3498DB', '#5DADE2', '#85C1E9'];
      colorSVGPaths(pinElement, blueShades);
      pinElement.setAttribute('data-marker', 'active');
      scrollIntoViewIfNeeded(pinElement);
    }
  }
};

export const handleSelectionSearch = (
  target: HTMLElement,
  isSelectionSearch: boolean,
  setSearchTerm: (value: React.SetStateAction<string>) => void,
) => {
  if (isSelectionSearch) {
    const span = target.querySelector("span[data-highlight='active']");
    const spanText = span?.textContent || target.textContent || '';
    setSearchTerm(spanText);
  }
};

export const handleSpanNodeSelection = (
  target: HTMLElement,
  sourceNodeIdSuggestionMap: GeneralizationSuggestionMap,
  selectedCatalogId: number,
  handleSourceChange: (sourceDocId: string) => void,
  editor1Ref: RefObject<EditorRef>,
  editor2Ref: RefObject<EditorRef>,
  currentTargetNodeIds: string[],
) => {
  const sourceNodeId = target.getAttribute('data-sourceid');
  const selectionId = target.getAttribute('data-selectionid');

  if (target.nodeName === 'SPAN' && sourceNodeId && selectionId) {
    target.style.setProperty('background-color', '#F9D1B9');

    if (sourceNodeIdSuggestionMap[sourceNodeId]) {
      const sourceDocId = sourceNodeIdSuggestionMap[sourceNodeId].sourceFileId;

      if (sourceDocId && selectedCatalogId !== +sourceDocId) {
        handleSourceChange(sourceDocId.toString());
      } else if (sourceDocId) {
        highlightMatchingElements(editor1Ref, editor2Ref, currentTargetNodeIds);
      }
    }
  }
};

export const handleTextSelection = (
  currentTargetNodeIds: string[],
  editor1Ref: RefObject<EditorRef>,
  getCurrentNodeId: (id: string, editorRef?: RefObject<EditorRef>) => string,
  handleSourceNodeIds: (nodeId?: string[]) => void,
  handleTargetNodeIds: (nodeId?: string[]) => void,
  selectedCells: HTMLElement[],
  sourceNodeIds: string[],
  shouldUpdateSourceNodeIds: boolean,
  textSelectionNode: string | null,
) => {
  if (textSelectionNode && selectedCells.length <= 1) {
    const isMultipleNodeSelected = !(
      currentTargetNodeIds.length === 1 && currentTargetNodeIds[0] === textSelectionNode
    );

    if (isMultipleNodeSelected) {
      const cellNodeId = getCellNodeId(textSelectionNode, editor1Ref);

      if (cellNodeId) {
        if (currentTargetNodeIds.includes(cellNodeId)) return false;
        handleTargetNodeIds([getCurrentNodeId(cellNodeId)]);
      } else {
        handleTargetNodeIds([textSelectionNode]);
      }
      // Reset source node ids
      handleSourceNodeIds();
    } else if (shouldUpdateSourceNodeIds) {
      handleSourceNodeIds(sourceNodeIds);
    }
    return false;
  }
  return true;
};

export const getFilteredCells = (
  cells: string[],
  mappingType: string,
  currentTargetNodeIds: string[],
  editor1Ref: RefObject<EditorRef>,
  nodeIdsFromAIResponse: string[],
): string[] => {
  return cells.filter((cell) => {
    const element = getElement(editor1Ref, cell) as HTMLElement;
    if (mappingType === 'auto-mapping') {
      return isNodeHighlighted(element);
    } else {
      const isAiResponseNode = nodeIdsFromAIResponse.includes(
        element?.getAttribute('data-nodeid') || '',
      );
      const isCurrentTargetNode = element?.getAttribute('data-nodeid') === currentTargetNodeIds[0];

      return isCellEmpty(element) || isAiResponseNode || isCurrentTargetNode;
    }
  });
};

export const convertAIResponseToSuggestions = (
  response: AIResponse,
  editorRef: RefObject<EditorRef>,
  sessionId: number | undefined,
  existingSuggestions?: GeneralizationSuggestionMap,
  selected = false,
) => {
  return Object.entries(response).flatMap(([targetNodeId, sourceSuggestions]) =>
    sourceSuggestions.map((suggestion) => {
      const [sourceNodeId] = Object.keys(suggestion);
      const { sourceFileId, value: targetValue, order } = Object.values(suggestion)[0];
      let previousSuggestionId = null;
      if (existingSuggestions && existingSuggestions[targetNodeId]) {
        previousSuggestionId = existingSuggestions[targetNodeId].id;
      }
      return {
        sourceFileId,
        sourceNodeId,
        targetNodeId,
        targetValue,
        order,
        targetFileId: getCatalogId(editorRef, targetNodeId),
        mapperType: 'ai',
        oldTargetValue: '<br>',
        sessionId,
        sourcePageNumber: 0,
        sourceTableColumn: 0,
        sourceTableNumber: 0,
        sourceTableRow: 0,
        targetTableColumn: 0,
        targetTableNumber: 0,
        targetTableRow: 0,
        selected,
        previousSuggestionId,
      };
    }),
  );
};

export const processAISuggestions = (suggestionsOutput: GeneralizationSuggestion[]): AIResponse => {
  const aiSuggestions = suggestionsOutput.reduce<AIResponse>((acc, suggestion) => {
    const { targetNodeId, sourceNodeId, targetValue, sourceFileId } = suggestion;

    if (sourceNodeId && targetNodeId && targetValue !== undefined) {
      const value = {
        [sourceNodeId]: {
          value: targetValue,
          sourceFileId: sourceFileId,
          groupingVariables: [],
        },
      };

      acc[targetNodeId] = acc[targetNodeId] ? [...acc[targetNodeId], value] : [value];
    }

    return acc;
  }, {});
  return aiSuggestions;
};

export const isCopyColumnOrRowCommand = (nodes: NodeListOf<Element>) => {
  return (
    nodes.length > 1 &&
    (nodes[0].nodeName === 'TH' || nodes[0].nodeName === 'TR' || nodes[0].nodeName === 'TD') &&
    nodes[1].nodeName === 'TD'
  );
};

export const getPreviousNode = (node: Element, nodeName: 'TH' | 'TR' | 'TD') => {
  if (nodeName === 'TH' || nodeName === 'TD') {
    return node.previousElementSibling;
  } else if (nodeName === 'TR') {
    const cellIndex = Array.from(node.parentElement?.children || []).indexOf(node);
    const previousRow = node.parentElement?.previousElementSibling;
    return previousRow?.children[cellIndex] || null;
  }
};

export const getDeletedNodes = (
  originalDoc: Document | undefined,
  updatedDoc: Document | undefined,
) => {
  const deletions: string[] = [];

  if (originalDoc && updatedDoc) {
    const updatedNodes = updatedDoc.querySelectorAll<HTMLElement>('[data-nodeId]');
    const originalNodes = originalDoc.querySelectorAll<HTMLElement>('[data-nodeId]');

    const updatedNodesMap: Record<string, boolean> = {};
    updatedNodes.forEach((node) => {
      const nodeId = node.getAttribute('data-nodeId');
      if (nodeId) updatedNodesMap[nodeId] = true;
    });

    originalNodes.forEach((node) => {
      const nodeId = node.getAttribute('data-nodeId');
      if (nodeId && !updatedNodesMap[nodeId]) deletions.push(nodeId);
    });
  }

  return deletions;
};

export const columnCells = (
  intermediateNode: HTMLTableElement,
  nodeIndex: number,
  originalCellsCount: number,
) => {
  try {
    return Array.from((intermediateNode as HTMLTableElement).rows).reduce(
      (cells: string[], row: HTMLTableRowElement) => {
        const rowCellCount = row.cells.length;
        let index = (nodeIndex - originalCellsCount + rowCellCount) % rowCellCount;
        index = index < 0 ? index + rowCellCount : index;
        const node = row.cells[index]?.getAttribute('data-nodeid');

        if (node) {
          cells.push(node);
        }

        return cells;
      },
      [],
    );
  } catch (error) {
    toast.error('Selected table has bad formatting');
    console.error(error);
    return [];
  }
};

export const rowCellNodeIds = (intermediateNode: Element | null) => {
  const columnCells = intermediateNode?.querySelectorAll('td') || [];
  if (columnCells.length === 0) return [];
  return Array.from(columnCells).map((td) => td.getAttribute('data-nodeid') || '');
};

export const fixTablesFormatting = (filteredHtml: string) => {
  const fixedTablesBody = fixTablesWithMissingTbody(filteredHtml);
  return fixTableCells(fixedTablesBody);
};

const fixTablesWithMissingTbody = (htmlContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  const tables = doc.querySelectorAll('table');

  tables.forEach((table) => {
    const thead = table.querySelector('thead');
    if (thead && !table.querySelector('tbody')) {
      const tbody = document.createElement('tbody');

      const trs = Array.from(thead.querySelectorAll('tr'));
      trs.slice(1).forEach((tr) => {
        tbody.appendChild(tr);
      });

      table.appendChild(tbody);
    }
  });

  return doc.documentElement.outerHTML;
};

export const gvCategoriesArray = (groupingVariables: IGroupingVariables): ICategory[] => {
  return Object.entries(groupingVariables).map(([title, options]: [string, string[]]) => ({
    title,
    options,
  }));
};

export const getColorForAcceptedSuggestion = (mapperType: string) => {
  switch (mapperType) {
    case mappingByType.ai:
      return AUTHORING_COLORS.AI_ACCEPTED;
    case mappingByType.automapping:
      return TEMPLATE_TRAINING_COLORS.APPROVED;
    default:
      return AUTHORING_COLORS.MANUAL_ACCEPTED;
  }
};

export const getColorForAiSuggestion = (mappingType: string) =>
  mappingType === MappingTypeEnum.AUTO_MAPPING
    ? AUTHORING_COLORS.MANUAL_ACCEPTED
    : AUTHORING_COLORS.AI_SUGGESTION;

export const updateNewNodeAttributes = (nodes: HTMLElement[], generatedDocumentId: string) => {
  nodes.forEach((node) => {
    if (node.tagName !== 'BR') {
      setAttributesAndOrder(node, generatedDocumentId, true);
      if (node.nodeType === Node.ELEMENT_NODE) {
        node.setAttribute('data-newnode', 'true');
      }
      updateNewNodeAttributes(Array.from(node.childNodes) as HTMLElement[], generatedDocumentId);
    }
  });
};

export const processImageSuggestions = (data: {
  imageSuggestions: GeneralizationSuggestion[];
  t2: GeneralizationDocument[];
}) => {
  const uniqueSourceFileIds = Array.from(
    new Set(data.imageSuggestions.map((suggestion) => suggestion.sourceFileId)),
  );

  const sourceFiles = data.t2.filter((sourceFile) => uniqueSourceFileIds.includes(sourceFile.id));

  const imageSuggestionsObject: { [key: number]: GeneralizationSuggestion[] } = {};
  data.imageSuggestions.forEach((suggestion: GeneralizationSuggestion) => {
    if (!imageSuggestionsObject[suggestion.sourceFileId]) {
      imageSuggestionsObject[suggestion.sourceFileId] = [];
    }
    imageSuggestionsObject[suggestion.sourceFileId].push(suggestion);
  });

  return { sourceFiles, imageSuggestionsObject };
};

export const escapeRegex = (string: string) => string.replace(/[^\w\s]/g, (match) => `\\${match}`);

export const escapeHtml = (htmlString: string) => {
  const element = document.createElement('div');
  element.innerText = htmlString;
  return element.innerHTML;
};
