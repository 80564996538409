import { z } from 'zod';

export const versionSchema = z.string().superRefine((value, ctx) => {
  // Should allow whole number versions
  const regex = /^\d{1,3}\.\d{1,2}$|^\d{1,3}$/;
  // Validate the version format to not allow more than 3 digits before and 1 digit after the decimal point
  if (!regex.test(value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'Version should have up to 3 digits and 2 digits after the decimal point (e.g., 1.01)',
    });
  }
});

export const authoringSchema = z.object({
  documentName: z.string().min(1),
  section: z.string({ message: 'Invalid or empty section' }).nullable().optional(),
  version: versionSchema,
  useLLM: z.boolean().optional(),
});
