import { FC, useRef, useState } from 'react';
import { Button, SlideOver } from '@GxT-Team/gxt-components';
import { CatalogItemFormValues, CatalogItemProps } from './CatalogItem.types';
import CatalogItemForm from './CatalogItemForm';
import { useCatalogItems } from '../../../../services/api';

const CatalogItem: FC<CatalogItemProps> = ({ isOpen, onClose, defaultValues }) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const formRef = useRef<{ submitForm: () => void }>(null);
  const [itemCount, setItemCount] = useState<number>(1);
  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const { addCatalogItem, updateCatalogItem } = useCatalogItems();

  const handleSubmitData = async (data: CatalogItemFormValues) => {
    if (!defaultValues?.id) {
      data.type &&
        addCatalogItem.mutate({
          ...data,
          type: data.type,
          associatedCatalogItemId: data.associatedCatalogItemId,
        });
    } else {
      updateCatalogItem.mutateAsync({ ...data, id: defaultValues?.id });
    }
    onClose();
  };

  const heading = defaultValues?.id ? 'Edit dropdown item' : 'Create new dropdown item';

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        {heading}
      </SlideOver.Header>
      <CatalogItemForm
        defaultValues={defaultValues}
        handleSubmitData={handleSubmitData}
        updateFormValidity={setFormIsValid}
        ref={formRef}
        itemCount={itemCount}
        fields={defaultValues?.id ? ['name'] : undefined}
      />
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex  justify-between items-center flex-grow flex-shrink flex-basis-0 w-full">
          <div>
            {!defaultValues?.id && (
              <Button
                variant="primary"
                disabled={!formIsValid}
                onClick={() => {
                  setItemCount((prev) => prev + 1);
                }}>
                + Add New Item
              </Button>
            )}
          </div>
          <div className="flex gap-4">
            <Button variant="primary" onClick={handleFormSubmit}>
              Save
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default CatalogItem;
