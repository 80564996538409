// React related imports
import { useContext, useMemo } from 'react';

// Context and hooks
import { GeneralizationContext } from '../../../../contexts/GeneralizationContext';

// Components
import SearchableDropdown from '../../../../components/elements/SearchableDropdown';
import SvgIcon from '../../../../components/elements/SvgIcon';

// Others
import { Button, Dropdown, Switch, Badge } from '@GxT-Team/gxt-components';
import { AuthoringActionsProps, BadgeType } from './types';
import { AuthoringTargetSaveState } from '../../hooks/types';

const AuthoringActions = ({
  suggestionOptions,
  searchOptions,
  isFullScreen,
  handleFullScreen,
  handleSearchTerm,
  customTextOnSelect,
  handleAiDropDownChange,
  targetChangesStatus,
  resetPlaceholder,
  setResetPlaceholder,
  onModeChange,
  editMode,
}: AuthoringActionsProps) => {
  const { setSelectionSearch, selectionSearch } = useContext(GeneralizationContext);

  const badgesProps = useMemo(() => {
    const badges: Record<AuthoringTargetSaveState, BadgeType> = {
      unchanged: {
        value: 'Nothing to save',
        className: '!bg-neutral-100 !text-neutral-300',
        type: 'autosave',
      },
      unsaved: {
        value: 'Unsaved Changes',
        className: '!bg-warning-50 !text-warning-200',
        type: 'nosave',
      },
      saved: {
        value: 'Saved',
        className: '',
        type: 'autosave',
      },
      autoSaved: {
        value: 'Autosaved',
        className: '',
        type: 'autosave',
      },
      processing: {
        value: 'Processing',
        className: '!bg-warning-100 !text-primary-400',
        type: 'autosave',
        showIcon: false,
      },
      processed: {
        value: 'Processed',
        className: '',
        type: 'autosave',
      },
    };
    return badges[targetChangesStatus];
  }, [targetChangesStatus]);

  return (
    <div className="mb-5 flex justify-between items-center space-x-6">
      <SearchableDropdown
        options={
          suggestionOptions?.length > 0 ? [...suggestionOptions, ...searchOptions] : searchOptions
        }
        onOptionSelected={handleAiDropDownChange}
        placeholder={suggestionOptions?.length ? 'Suggestions Available' : 'Search Suggestions...'}
        className="flex-1"
        customTextOnSelect={customTextOnSelect}
        onSearch={handleSearchTerm}
        resetPlaceholder={resetPlaceholder}
        setResetPlaceholder={setResetPlaceholder}
      />
      <div className="flex items-center space-x-6">
        <Dropdown
          customStyles={{
            placeholder: {
              color: 'var(--neutral-400)',
              backgroundColor: editMode ? 'var(--primary-50)' : '',
            },
          }}
          dropdownIcon
          onSelect={(option) => onModeChange(option.value)}
          options={['Editing Mode', 'Data Transfer Mode'].map((option) => ({
            value: option,
            disabled: option === 'Editing Mode' ? editMode : !editMode,
          }))}
          placeholder="Data Transfer Mode"
          position="bottom"
          type="select"
        />
        <div
          data-testid="global-search-container"
          className="flex items-center space-x-3 content-box-sizing">
          <Switch
            checked={selectionSearch}
            onChange={() => setSelectionSearch((prev: boolean) => !prev)}
            offColor="var(--system-50, #fff)"
            onColor="var(--primary-200, #177ba6)"
          />
          <h5 className="text-neutral-400 font-normal" data-testid="global-search-text">
            Global <br /> Search
          </h5>
        </div>
        <div className="flex items-center space-x-3">
          {badgesProps && (
            <Badge
              className={`${badgesProps.className}`}
              label={badgesProps.value}
              type={badgesProps.type}
              showIcon={badgesProps.showIcon}
            />
          )}
          <Button variant="primary" circular={true} onClick={handleFullScreen}>
            <SvgIcon data-testid="zoom-in-icon" iconType={isFullScreen ? 'zoom-out' : 'zoom-in'} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AuthoringActions;
