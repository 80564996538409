// React-related imports
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts and Hooks
import { MappingContext } from '../../../contexts/MappingContext';
import { GeneralizationContext } from '../../../contexts/GeneralizationContext';
import useSearchResult from '../../../hooks/useSearchResult';
import { useTextSelection } from '../../../hooks/generalizationHooks/useTextSelection';
import { useHistory } from '../../../hooks/useHistory';
import { useGeneralizationNavigation } from '../../../hooks/generalizationHooks/useGeneralizationNavigation';
import useAsyncOperation from '../../../hooks/useAsyncOperation';

// Helpers
import {
  generateRandomColor,
  deepCopyDocument,
  getAllNodesIdsInElement,
  getCatalogId,
  getCatalogWithSourceIdsFromAIResponse,
  getElement,
  getSuggestionByTargetNodeId,
  getCellElement,
  getUpdatedAiResponse,
  highlightSuggestion,
  updateAllSpanBackgroundColors,
  newCatalogNodes,
  extractNodeIdsFromAIResponse,
  removeHighlightSuggestions,
  getNodeIdAndValues,
  highlightMatchingElements,
  filteredAiResponse,
  getAllCells,
  setTargetElement,
  getCellNodeId,
  handleSpanNodeSelection,
  handleSelectionSearch,
  handleTextSelection,
  getFilteredCells,
  removeChildNodeTextContent,
  getMergedEditsIdsWithAiResponseIds,
  convertAIResponseToSuggestions,
  getDeletedNodes,
  getColorForAcceptedSuggestion,
  getColorForAiSuggestion,
  applyColorForNewCells,
  getNodeIdFromAttribute,
} from '../../../helpers/generalizationHelpers';
import {
  autoAcceptSuggestions,
  undoSuggestion,
  saveSuggestionsWithNodes,
  fetchMappingSessionComments,
} from '../../../services/apiCalls';
import { notifyError } from '../../../helpers/utils';
import {
  applyCustomSVGs,
  applySVGToElement,
  getActiveSvgPin,
  handleSvgPinActivation,
  removeActivePin,
  removeAllSvgPins,
} from '../../../helpers/imageTaggingHelper';
import {
  excludeStyleAttribute,
  getUpdatedCells,
  removeSingleBrChild,
} from '../../../helpers/documentComparisonHelpers';

// Constants
import { AUTHORING_COLORS, mappingByType, sessionMapperType } from '../../../constants';
import { AddSourcesPreviousAction } from '../../catalog/util/constants';

// Types
import {
  AcceptanceMethodEnum,
  AIResponse,
  EditorRef,
  GeneralizationSuggestion,
  IdChangesMap,
  MappingTypeEnum,
  NewSuggestion,
} from '../../../types';

// Other dependencies
import _ from 'lodash';
import { Editor as TinyMCEEditor } from 'tinymce';
import { useImageTagging } from './useImageTagging';
import { initialImageTagValues } from '../utils/constants';
import { AuthoringTargetSaveState, TargetSaveState } from './types';
import { useGeneralizationComments } from '../../../hooks/useGeneralizationComments';
import { useMappingSessionAPI } from '../../../services/api';

const AUTOSAVE_DELAY = 20000; // 20 seconds

export const useGeneralizationAuthoring = () => {
  const {
    editor1Ref,
    setEditor1Content,
    editor2Ref,
    sourceFileDoc,
    setMappingComments,
    addCommentBubble,
    mappingComments,
  } = useContext(MappingContext);

  const {
    loadingGeneratedDocument,
    acceptedSuggestions,
    aiResponse,
    generatedDocument,
    selectedCatalogId,
    loadingSourceDocument,
    sessionId,
    handleSourcechange,
    getSourceNodeIndex,
    setAcceptedSuggestions,
    setLoadingSourceDocument,
    setLastSaved,
    setSearchTerm,
    selectionSearch,
    aiResponseCopy,
    clearAISuggestions,
    mappingType,
    selectedValue,
    cells,
    setCells,
    setGeneratedDocumentWithAcceptedSuggestions,
    setLockSourceFile,
    lockSourceFile,
    targetNodeIdSuggestionMap,
    sourceNodeIdSuggestionMap,
    isAutoSave,
    initialTarget,
    setInitialTarget,
  } = useContext(GeneralizationContext);
  const { autoFillSuggestions } = useMappingSessionAPI();

  const params = useParams();
  const navigate = useNavigate();

  const { addHistory, undo, redo, isUndoAvailable, isRedoAvailable, setHistory } = useHistory<
    GeneralizationSuggestion | NewSuggestion
  >([], `${params.id}_history`);

  const [isNewSuggestionsActive, setIsNewSuggestionsActive] = useState(false);
  const [updatedAiResponse, setUpdatedAiResponse] = useState<AIResponse>({});
  const [lockedAiResponse, setLockedAiResponse] = useState<AIResponse>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isDisabledApprove, setIsDisabledApprove] = useState<boolean>(true);
  const [suggestionIndex, setSuggestionIndex] = useState<{ value: number; nodeId?: string }>({
    value: 0,
    nodeId: '',
  });
  const [isAutofill, setIsAutofill] = useState(false);
  const [currentTargetNodeIds, setCurrentTargetNodeIds] = useState<string[]>([]);
  const [isImageNodeSelected, setIsImageNodeSelected] = useState(false);
  const [currentSourceNodeIds, setCurrentSourceNodeIds] = useState<string[]>([]);
  const [previousTargetNodeIds, setPreviousTargetNodeIds] = useState<string[]>([]);
  const [previousSourceNodeIds, setPreviousSourceNodeIds] = useState<string[]>([]);
  const [suggestionNodeValues, setSuggestionNodeValues] = useState<Array<[string, HTMLElement]>>(
    [],
  );
  const [aiSuggestionList, setAiSuggestionList] = useState<Array<{ [key: string]: any }>>([]);
  const [isCtrlPressed, setIsCtrlPressed] = useState<boolean>(false);
  const [colors, setColors] = useState<string[]>([]);
  const { isSearched, setIsSearched, searchResult, setSearchResult } = useSearchResult();
  const [isSnappingEnabled, setIsSnapEnabled] = useState(false);
  const [copiedAIResponse, setCopiedAIResponse] = useState<AIResponse>({});
  const [isHeaderCellSelected, setIsHeaderCellSelected] = useState(false);
  const [targetChangesStatus, setTargetChangesStatus] = useState<AuthoringTargetSaveState>(
    TargetSaveState.UNCHANGED,
  );
  const [lastChangeTime, setLastChangeTime] = useState<number | null>(null);

  const timerRef = useRef<NodeJS.Timeout>();

  const handleSnappingToggle = () => {
    setIsSnapEnabled((prevState) => !prevState);
  };

  const nodeIdsFromAIResponse = useMemo(
    () => extractNodeIdsFromAIResponse(updatedAiResponse, editor1Ref),
    [updatedAiResponse, editor1Ref.current],
  );

  const filteredCells = getFilteredCells(
    cells,
    mappingType,
    currentTargetNodeIds,
    editor1Ref,
    nodeIdsFromAIResponse,
  );

  const newAiResponse = useMemo(() => {
    if (!aiResponse || !acceptedSuggestions) return {};
    return getUpdatedAiResponse(aiResponse, acceptedSuggestions) as AIResponse;
  }, [aiResponse, acceptedSuggestions]);

  const {
    isNextCellAvailable: isNextAuthoringCellAvailable,
    isPreviousCellAvailable: isPreviousAuthoringCellAvailable,
    navigateToCell: navigateToAuthoringCell,
    updateIndex,
  } = useGeneralizationNavigation(
    filteredCells,
    -1,
    getCellNodeId(currentTargetNodeIds[0], editor1Ref),
  );

  const {
    templateSelectionOnClickHandler,
    sourceSelectionOnClickHandler,
    updateTextSelectionNode,
    linkTextSelectionNode,
    sourceSelection,
    targetSelection,
    resetAllActiveTextHighlights,
    resetSourceTextHighlight,
  } = useTextSelection();

  const handleLockedFileChange = () => {
    setLockSourceFile((prev) => !prev);
  };

  const autoFillHandler = async () => {
    setIsAutofill(true);
    if (sessionId) {
      const result = await autoFillSuggestions.mutateAsync({ id: sessionId });
      const tempSuggestions = [...(acceptedSuggestions || []), ...result.data.data];
      setAcceptedSuggestions(tempSuggestions);
      tempSuggestions.forEach((s) => {
        if (s) setTargetElement(editor1Ref, s.targetValue, s.targetNodeId);
      });
      setIsAutofill(false);
      const newTarget = deepCopyDocument(editor1Ref.current.getDoc());
      if (newTarget) setInitialTarget(newTarget);
    }
  };

  const sourceNodeId = (): string[] | undefined => {
    const currentTemplateNodeSuggestions =
      acceptedSuggestions &&
      acceptedSuggestions.filter(
        (suggestion) => suggestion.targetNodeId === currentTargetNodeIds[0],
      );
    if (currentTemplateNodeSuggestions && currentTemplateNodeSuggestions.length > 1)
      return currentTemplateNodeSuggestions.map((suggestion) => suggestion.sourceNodeId);
    else {
      // const sourceNodeId = _.get(
      //   _.keys(
      //     _.get(
      //       _.get(
      //         updatedAiResponse,
      //         `${getCurrentNodeId(currentTargetNodeIds[0])}`
      //       ),
      //       `[${suggestionIndex.value}]`
      //     )
      //   ),
      //   "[0]"
      // );
      const sourceNodeIds = _.keys(
        _.get(
          _.get(updatedAiResponse, `${getCurrentNodeId(currentTargetNodeIds[0])}`),
          `[${suggestionIndex.value}]`,
        ),
      );
      return sourceNodeIds.length ? [sourceNodeIds[sourceNodeIds.length - 1]] : [];
    }
  };

  const sourceFileId = (): number | undefined =>
    _.get(
      _.get(
        _.values(
          _.get(
            _.get(updatedAiResponse, `${getCurrentNodeId(currentTargetNodeIds[0])}`),
            `[${suggestionIndex.value}]`,
          ),
        ),
        '[0]',
      ),
      'sourceFileId',
    );

  const highlightSuggestions = (
    editorRef: any,
    nodeIds: string[],
    color: string | null = null,
    isScroll = false,
  ) => {
    nodeIds?.forEach((nodeId) => {
      const element = getElement(editorRef, nodeId);
      if (element) {
        const highlightColor = color || getSuggestionColor(nodeId);
        highlightSuggestion({
          element,
          color: highlightColor,
          isScroll,
        });
      }
    });
  };

  const getSuggestionColor = (nodeId: string) => {
    const element = getElement(editor1Ref, nodeId);
    const nodeIds = getAllNodesIdsInElement(element);
    const acceptedSuggestion = acceptedSuggestions?.find((suggestion) =>
      nodeIds.includes(suggestion.targetNodeId),
    );

    if (acceptedSuggestion) {
      return getColorForAcceptedSuggestion(acceptedSuggestion.mapperType || '');
    } else if (aiResponse && nodeIds.some((id) => id in aiResponse)) {
      return getColorForAiSuggestion(mappingType);
    }
  };

  const getCurrentNodeId = (
    id: string,
    editorRef = editor1Ref,
    aiResponse: AIResponse = updatedAiResponse,
  ) => {
    const element = getElement(editorRef, id);
    if (!element) return '';

    const cellElement = getCellElement(element, editor1Ref);
    const allNodesIds = getAllNodesIdsInElement(cellElement ?? element);
    const nodeWithSuggestion = allNodesIds.find((nodeId) => !!aiResponse[nodeId]);
    return nodeWithSuggestion || cellElement?.getAttribute('data-nodeid') || id;
  };

  const handleSourceNodeIds = (nodeIds: string[] = []) => {
    setCurrentSourceNodeIds((prev) => {
      setPreviousSourceNodeIds([...prev]);
      return nodeIds;
    });
  };

  const handleTargetNodeIds = (nodeIds: string[] = []) => {
    setCurrentTargetNodeIds((prevTargetNodeIds) => {
      setPreviousTargetNodeIds(prevTargetNodeIds);
      return nodeIds;
    });
  };

  const handleReset = () => {
    handleTargetNodeIds();
    handleSourceNodeIds();
    resetAllActiveTextHighlights();
    if (initialTarget && editor1Ref.current.isDirty()) {
      setGeneratedDocumentWithAcceptedSuggestions(initialTarget);
    }
  };

  const targetOnClickHandler = ({ target }: { target: any }, editor: any) => {
    const selectedCells = editor.model.table.getSelectedCells();
    const textSelectionNode =
      selectedCells.length > 1 ? null : templateSelectionOnClickHandler(target);
    const editor2Body = editor2Ref.current.getBody();

    const spans = editor2Body.querySelectorAll('span[data-selectionid][data-sourceid]');

    updateAllSpanBackgroundColors(spans);
    handleSelectionSearch(target, selectionSearch, setSearchTerm);
    handleSpanNodeSelection(
      target,
      sourceNodeIdSuggestionMap,
      selectedCatalogId,
      handleSourcechange,
      editor1Ref,
      editor2Ref,
      currentTargetNodeIds,
    );

    const sourceIds = sourceNodeId();
    const updateSourceNodeIds = !!sourceIds && !_.isEqual(sourceIds, currentSourceNodeIds);
    const removeTextHighlight = handleTextSelection(
      currentTargetNodeIds,
      editor1Ref,
      getCurrentNodeId,
      handleSourceNodeIds,
      handleTargetNodeIds,
      selectedCells,
      sourceIds || [],
      updateSourceNodeIds,
      textSelectionNode,
    );

    if (removeTextHighlight) resetAllActiveTextHighlights();
    else return;

    setIsSearched(false);

    const cellElement = getCellElement(target, editor1Ref.current.getBody());
    const nodeId = cellElement?.getAttribute('data-nodeid');

    // Handle single table cell as target node
    if (nodeId) {
      setIsImageNodeSelected(false);
      // Handle single Ctrl key press
      if (isCtrlPressed) {
        setCurrentTargetNodeIds((prev: any) => {
          setPreviousTargetNodeIds(prev);
          if (prev.includes(nodeId)) {
            return prev.filter((id: string) => id !== nodeId);
          } else {
            const cellNodeId = getCellNodeId(getCurrentNodeId(nodeId), editor1Ref);
            cellNodeId && updateIndex(cellNodeId);
            return [...prev, getCurrentNodeId(nodeId)];
          }
        });
        const isAlreadyExists = currentTargetNodeIds.includes(nodeId);
        if (isAlreadyExists) {
          const nodeIndex = currentTargetNodeIds.indexOf(nodeId);
          setColors((prevColors) => {
            const updatedColors = [...prevColors];
            updatedColors.splice(nodeIndex, 1);
            return updatedColors;
          });
          setCurrentSourceNodeIds((prevSourceNodeIds) => {
            setPreviousSourceNodeIds(prevSourceNodeIds);
            const updatedSourceNodeIds = [...prevSourceNodeIds];
            updatedSourceNodeIds.splice(nodeIndex, 1);
            return updatedSourceNodeIds;
          });
        } else {
          setColors((prevColors) => [
            ...prevColors,
            prevColors.length === 0
              ? AUTHORING_COLORS.SELECTED
              : generateRandomColor(prevColors.length),
          ]);
        }
      } else {
        const currentNodeId = getCurrentNodeId(nodeId);

        const referenceSelectionSuggestion = targetNodeIdSuggestionMap[currentNodeId];
        if (isNewSuggestionsActive && !currentTargetNodeIds.includes(currentNodeId)) {
          setCurrentTargetNodeIds((prev: string[]) => {
            setPreviousTargetNodeIds(prev);
            if (prev.length === 1 && !referenceSelectionSuggestion?.sourceFileId) return prev;
            return [...prev, currentNodeId];
          });

          if (currentTargetNodeIds.length === 1) {
            if (!referenceSelectionSuggestion?.sourceFileId) {
              notifyError('Invalid selection. Please try again.');
            } else if (referenceSelectionSuggestion?.sourceFileId) {
              navigate('/add-source', {
                state: {
                  sessionId,
                  previousAction: AddSourcesPreviousAction.DYNAMIC_MAPPING,
                  dynamicMappingPayload: {
                    targetSelectionNodeId: currentTargetNodeIds.at(0),
                    referenceSelectionNodeId: currentNodeId,
                    referenceSelectionSuggestionId: referenceSelectionSuggestion.id,
                  },
                },
              });
            }
          }
          return;
        }

        handleSourceNodeIds();
        setColors([AUTHORING_COLORS.SELECTED]);
        handleTargetNodeIds([getCurrentNodeId(nodeId)]);
        const cellNodeId = getCellNodeId(getCurrentNodeId(nodeId), editor1Ref);
        cellNodeId && updateIndex(cellNodeId);
      }
    } else if (!_.isEmpty(selectedCells)) {
      // Handle multiple table cell as target node
      setIsImageNodeSelected(false);
      setIsDisabledApprove(_.isEmpty(selectedCells));

      const nodeIds = selectedCells.map((cell: HTMLElement) =>
        getCurrentNodeId(cell.getAttribute('data-nodeid') || ''),
      );

      if (_.isEqual(currentTargetNodeIds, nodeIds)) {
        editor.selection.collapse();
        return;
      }

      setCurrentTargetNodeIds((prev: any) => {
        setPreviousTargetNodeIds(prev);
        const newIds: string[] = nodeIds.filter((id: string) => !prev.includes(id));

        if (isCtrlPressed) {
          const newColors = [...prev, ...newIds].map((id: any, index: number) =>
            generateRandomColor(colors.length + index),
          );
          setColors((prev) => [...prev, ...newColors.splice(prev.length)]);
          return [...prev, ...newIds];
        } else {
          const newColors = nodeIds.map((id: string, index: number) => generateRandomColor(index));
          handleSourceNodeIds();
          setColors(newColors);
          return [...nodeIds];
        }
      });
      selectedCells.forEach((cell: HTMLElement) => {
        editor.dom.setAttrib(cell, 'data-mce-selected', null);
      });
    } else if (!cellElement) {
      const nodeId = target.getAttribute('data-nodeid');
      setColors([AUTHORING_COLORS.SELECTED]);
      if (nodeId && (target.nodeName === 'P' || target.nodeName === 'IMG')) {
        handleTargetNodeIds([nodeId]);
        handleSourceNodeIds();
      }

      if (nodeId && target.nodeName === 'IMG') {
        setIsImageNodeSelected(true);
        return;
      }

      setIsImageNodeSelected(false);
    }
    editor.selection.collapse();
  };
  const handleSourceImageNodeSelection = (sourceNode: HTMLElement, targetNode: HTMLElement) => {
    if (!sourceNode) return;

    const isImageToImageSelection =
      currentTargetNodeIds.length && targetNode?.tagName === 'IMG' && sourceNode.nodeName === 'IMG';

    const nodeIdToUse = isImageToImageSelection
      ? getNodeIdFromAttribute(sourceNode)
      : getNodeIdFromAttribute(sourceNode.parentElement);

    handleSourceNodeIds([nodeIdToUse]);
  };

  const sourceDocumentOnClickHandler = (event: MouseEvent, editor: any) => {
    const target = event.target as HTMLElement;
    if (!target.parentElement || !currentTargetNodeIds.length) return;
    const sourceNodeId = target.parentElement.getAttribute('data-nodeid');

    if (
      currentTargetNodeIds.length === 1 &&
      isImageTagging &&
      target.nodeName === 'IMG' &&
      sourceNodeId &&
      event.offsetX &&
      event.offsetY
    ) {
      removeActivePin(editor.getBody());

      const parentWidth = target.parentElement.offsetWidth;
      const parentHeight = target.parentElement.offsetHeight;

      const zoomLevel = parseFloat((getComputedStyle(target.ownerDocument.body) as any).zoom);

      const offsetXInPercent = ((event.offsetX / zoomLevel) * 100) / parentWidth;
      const offsetYInPercent = ((event.offsetY / zoomLevel) * 100) / parentHeight;

      applySVGToElement(
        {
          positionX: offsetXInPercent,
          positionY: offsetYInPercent,
          targetId: currentTargetNodeIds[0],
        },
        sourceNodeId,
        editor2Ref,
        'active',
      );
      openTaggingSlideOver();
    }

    const selectedCells = editor.model.table.getSelectedCells();
    const textSelectionNode =
      selectedCells.length > 1 ? null : sourceSelectionOnClickHandler(target);
    if (textSelectionNode) {
      if (!_.isEqual(currentTargetNodeIds, [textSelectionNode]))
        handleSourceNodeIds([textSelectionNode]);
      return;
    }

    resetSourceTextHighlight();
    const targetNode = getElement(editor1Ref, currentTargetNodeIds[0]);
    if (target.nodeName === 'IMG' && targetNode) {
      handleSourceImageNodeSelection(target, targetNode);
      return;
    }

    const cellElement = getCellElement(target, editor2Ref.current.getBody());
    const clickedNodeId = target.getAttribute('data-nodeid') || '';
    const isTdElementWithDataNodeId = cellElement && cellElement.hasAttribute('data-nodeid');

    selectedCells.forEach((cell: HTMLElement) => {
      editor.dom.setAttrib(cell, 'data-mce-selected', null);
    });

    setCurrentSourceNodeIds((prev) => {
      setPreviousSourceNodeIds(prev);
      if (isCtrlPressed && isTdElementWithDataNodeId) {
        if (prev.includes(clickedNodeId)) {
          const index = prev.indexOf(clickedNodeId);
          setColors((prevColors) => {
            const updatedColors = [...prevColors];
            if (prev.length === prevColors.length - 1) {
              updatedColors.splice(index, 1);
            }
            return updatedColors;
          });
          return prev.filter((nodeId) => nodeId !== clickedNodeId);
        } else {
          if (prev.length + 1 <= colors.length) {
            return [...prev, clickedNodeId];
          }
        }
      } else if (isCtrlPressed) {
        const newSelectedNodeIds = selectedCells.map(
          (cell: HTMLElement) => cell.getAttribute('data-nodeid') || '',
        );

        const filteredNodeIds = newSelectedNodeIds.filter(
          (nodeId: string) => !prev.includes(nodeId),
        );

        if (prev.length + filteredNodeIds.length <= colors.length) {
          return [...prev, ...filteredNodeIds];
        }
      } else if (isTdElementWithDataNodeId) {
        return [clickedNodeId];
      } else if (!_.isEmpty(selectedCells)) {
        setIsDisabledApprove(_.isEmpty(selectedCells));
        const newSelectedNodeIds = selectedCells.map(
          (cell: HTMLElement) => cell.getAttribute('data-nodeid') || '',
        );
        if (newSelectedNodeIds.length <= colors.length) {
          return newSelectedNodeIds;
        } else {
          return newSelectedNodeIds.slice(0, colors.length);
        }
      }
      return target.hasAttribute('data-nodeid') ? [target.getAttribute('data-nodeid')] : prev;
    });
    editor.selection.collapse();
  };

  const getCellElementIds = (updatedAiResponse: AIResponse) => {
    return Object.keys(updatedAiResponse).map((id) => {
      const el = getElement(editor1Ref, id);
      if (el) {
        const cellElement = getCellElement(el, editor1Ref.current.getBody());
        return cellElement?.getAttribute('data-nodeid') || '';
      }
      return '';
    });
  };

  const addCommentBubbles = () => {
    mappingComments.forEach((comment) =>
      addCommentBubble(comment.mappingNumber, comment.targetNodeId),
    );
  };

  const highlightAllTargetSuggestions = (editorRef: EditorRef) => {
    const nodeIds: string[] = getCellElementIds(updatedAiResponse);
    const nodeValues = getNodeIdAndValues(nodeIds, editor1Ref);
    setSuggestionNodeValues(nodeValues);
    const mergedNodeIds = getMergedEditsIdsWithAiResponseIds(
      nodeIds,
      acceptedSuggestions,
      editor1Ref,
    );
    highlightSuggestions(editorRef, mergedNodeIds, null);
    highlightCurrentNodes();
    addCommentBubbles();
  };

  const suggestionsWithColors = useCallback(() => {
    if (_.keys(updatedAiResponse)?.length > 0) {
      const nodeIds: string[] = getCellElementIds(updatedAiResponse);
      return nodeIds?.map((nodeId) => ({
        targetNodeId: nodeId,
        color: getSuggestionColor(nodeId),
      }));
    }
  }, [updatedAiResponse]);

  const highlightAllSourceDocumentSuggestions = () => {
    if (selectedValue) {
      const nodeIds = memoizedCatalogWithSourceIds[selectedValue];
      applyCustomSVGs(svgValues, editor2Ref);
      const color = '#D3D3D3';
      highlightSuggestions(editor2Ref, nodeIds, color);
    }
  };

  const highlightPreviousNode = (
    editorRef: any,
    isSourceDocument = false,
    previousElement: HTMLElement | null,
  ) => {
    const previousElementNodeIds: string[] = getAllNodesIdsInElement(previousElement);
    highlightSuggestions(
      editorRef,
      previousElementNodeIds,
      isSourceDocument ? '#D3D3D3' : null,
      false,
    );
  };

  const highlightCurrentNode = (
    editorRef: any,
    defaultColor: string = AUTHORING_COLORS.SELECTED,
    currentElement: HTMLElement | null,
  ) => {
    const elementNodeIds: string[] = getAllNodesIdsInElement(currentElement);
    highlightSuggestions(editorRef, elementNodeIds, defaultColor, true);
    handleSvgPinActivation(editor2Ref, currentTargetNodeIds.at(0));
  };

  const highlightCurrentNodes = () => {
    currentTargetNodeIds.forEach((currentTargetNodeId, index) => {
      const element = getElement(editor1Ref, currentTargetNodeId);
      if (!element) return;
      const cellElement = getCellElement(element, editor1Ref);
      highlightCurrentNode(editor1Ref, colors[index], cellElement ?? element);
    });
  };

  const autofillSuggestions = () => {
    setLoadingSourceDocument(true);
    autoAcceptSuggestions(sessionId)
      .then((res) => {
        setAcceptedSuggestions((prevAcceptedSuggestions) => [
          ...[prevAcceptedSuggestions || []],
          ...res.data,
        ]);
        window.location.reload();
      })
      .catch((err) => {
        notifyError(err.message);
        console.error(err);
      })
      .finally(() => {
        setLoadingSourceDocument(false);
      });
  };

  const callSaveSuggestions = async (
    currentSuggestions: any,
    acceptanceMethodProp: AcceptanceMethodEnum,
    addToHistory = true,
  ) => {
    if (!generatedDocument || !generatedDocument?.id) return;
    const generatedDocumentId = generatedDocument.id;
    const newCatNodes = newCatalogNodes(editor1Ref, generatedDocument) ?? [];
    const deletedNodes = getDeletedNodes(initialTarget, editor1Ref?.current?.getDoc());
    if (!currentSuggestions.length && !newCatNodes.length && !deletedNodes.length) {
      return;
    }

    const suggestionsToSave = copiedAIResponse
      ? [
          ...currentSuggestions,
          ...convertAIResponseToSuggestions(copiedAIResponse, editor1Ref, sessionId),
        ]
      : currentSuggestions;
    let res;
    try {
      if (newCatNodes.length) {
        applyColorForNewCells(editor1Ref, newCatNodes, suggestionsToSave);
      }
      res = await saveSuggestionsWithNodes({
        acceptanceMethod: acceptanceMethodProp,
        suggestions: suggestionsToSave,
        newCatalogNodes: {
          nodes: newCatNodes,
          catalogId: generatedDocumentId,
        },
        deletedNodes: { nodes: deletedNodes, catalogId: generatedDocumentId },
        sessionId,
      });
      setCopiedAIResponse({});
    } catch (e) {
      notifyError('Error occurred in saving document');
      console.error(e);
    }

    if (!res?.data?.suggestions) {
      return;
    }

    const suggestions: GeneralizationSuggestion[] = res.data.suggestions;
    addToHistory && addHistory(suggestions);
    setAllCells();

    const suggestionMap = suggestions.reduce((map, suggestion) => {
      if (suggestion.selected) {
        map.set(suggestion.targetNodeId, suggestion);
      }
      return map;
    }, new Map());

    setLastSaved(new Date(res.data.lastSavedDate));
    setAcceptedSuggestions((prevAcceptedSuggestions) => {
      if (!prevAcceptedSuggestions) {
        return suggestions;
      }

      const newAcceptedSuggestions = [...prevAcceptedSuggestions];

      suggestionMap.forEach((value, key) => {
        const existingIndex = newAcceptedSuggestions.findIndex(
          (suggestion) => suggestion.targetNodeId === key,
        );
        if (value.targetValue?.includes('data-highlight=') || existingIndex === -1) {
          newAcceptedSuggestions.push(value);
        } else {
          newAcceptedSuggestions[existingIndex] = {
            ...newAcceptedSuggestions[existingIndex],
            ...value,
          };
        }
      });

      return newAcceptedSuggestions;
    });
    return suggestions;
  };

  const handleNewSuggestions = async (
    sourceData = initialImageTagValues,
    acceptanceMethod: AcceptanceMethodEnum,
  ) => {
    const { sourceDocumentData, ...restSourceData } = sourceData;
    if (!Array.isArray(acceptedSuggestions) || !generatedDocument) return;
    let x: string | undefined, y: string | undefined, svgElement: HTMLElement | null;

    const currentSuggestions = currentTargetNodeIds.map(
      (currentTargetNodeId, index): NewSuggestion | GeneralizationSuggestion => {
        let targetValue;
        let sourceValue;
        let oldTargetValue;
        const oldTargetElement = getElement(editor1Ref, currentTargetNodeId);

        const updatedSelection = updateTextSelectionNode();
        if (updatedSelection) {
          const {
            targetValue: targetVal,
            sourceValue: sourceVal,
            oldTargetNodeValue,
          } = updatedSelection;
          targetValue = targetVal;
          sourceValue = sourceVal;
          oldTargetValue = oldTargetNodeValue;
        } else {
          oldTargetValue = oldTargetElement?.innerHTML;
        }
        let sourceDocumentElement = getElement(editor2Ref, currentSourceNodeIds[index]);
        if (sourceDocumentElement) {
          sourceDocumentElement = removeAllSvgPins(sourceDocumentElement, true) as HTMLElement;
        }

        const suggestion = getSuggestionByTargetNodeId(acceptedSuggestions, currentTargetNodeId);
        const mapperType: string =
          _.findIndex(aiResponse && aiResponse[currentTargetNodeId], (o) =>
            _.has(o, currentSourceNodeIds[index]),
          ) !== -1
            ? sessionMapperType[mappingType]
            : mappingByType.author;
        if (!targetValue) {
          if (sourceDocumentElement?.tagName === 'IMG' && oldTargetElement?.tagName === 'IMG') {
            targetValue = sourceDocumentElement?.getAttribute('src');
          } else {
            targetValue =
              sourceDocumentElement?.innerHTML &&
              sourceDocumentElement.innerHTML.replace(/ style="[^"]*background[^"]*"/g, '');
          }
          if (oldTargetElement?.tagName === 'IMG') {
            const nodeIndex = _.findIndex(aiResponse && aiResponse[currentTargetNodeId], (o) =>
              _.has(o, currentSourceNodeIds[index]),
            );
            const imageNode =
              aiResponse?.[currentTargetNodeId]?.[nodeIndex]?.[currentSourceNodeIds[index]];
            if (isImageNodeSelected && imageNode?.suggestionType === 'image') {
              targetValue = imageNode?.value;
            }
          }
        }

        if (currentTargetNodeIds.length === 1 && isImageTagging) {
          svgElement = getActiveSvgPin(editor2Ref.current.getDoc());
          x = svgElement?.style.left;
          y = svgElement?.style.top;
          if (svgElement) targetValue = sourceDocumentData;
          setTargetElement(editor1Ref, sourceDocumentData, currentTargetNodeId);
        }

        const sourceFileId = getCatalogId(editor2Ref, currentSourceNodeIds[index]) || 0;

        return suggestion
          ? ({
              ...suggestion,
              sourceFile: { id: sourceFileId },
              sourceFileId: sourceFileId,
              sourceNodeId: currentSourceNodeIds[index],
              sessionId,
              documentId: sourceFileId,
              mapperType,
              targetValue,
              targetFileId: generatedDocument.id,
              previousSuggestionId: suggestion.id,
              sourceValue: oldTargetElement?.tagName === 'IMG' ? undefined : sourceValue,
              positionX: x ? parseFloat(x) : null,
              positionY: y ? parseFloat(y) : null,
              suggestionType: oldTargetElement?.tagName === 'IMG' ? 'image' : 'text',
              ...restSourceData,
            } as GeneralizationSuggestion)
          : ({
              id: null,
              sessionId: sessionId,
              targetNodeId: currentTargetNodeId,
              sourceNodeId: currentSourceNodeIds[index],
              documentId: sourceFileId,
              targetFileId: generatedDocument.id,
              targetValue,
              mapperType: mapperType,
              sourceValue: oldTargetElement?.tagName === 'IMG' ? undefined : sourceValue,
              sourceFileId: sourceFileId,
              oldTargetValue,
              positionX: x ? parseFloat(x) : null,
              positionY: y ? parseFloat(y) : null,
              suggestionType: oldTargetElement?.tagName === 'IMG' ? 'image' : 'text',
              ...restSourceData,
            } as NewSuggestion);
      },
    );
    currentSuggestions.forEach((suggestion) => {
      if (!targetNodeIdSuggestionMap[suggestion.targetNodeId]) {
        const previousValues = {
          sessionId: sessionId,
          targetNodeId: suggestion.targetNodeId,
          sourceNodeId: '',
          documentId: getCatalogId(editor2Ref, currentSourceNodeIds[0]),
          targetValue: suggestion.oldTargetValue,
          mapperType: suggestion.mapperType,
          sourceValue: '',
          id: suggestion.id,
        } as NewSuggestion;
        addHistory([previousValues]);
      }
      if (!svgElement) {
        setTargetElement(editor1Ref, suggestion.targetValue, suggestion.targetNodeId);
      }
    });

    try {
      await callSaveSuggestions(currentSuggestions, acceptanceMethod, false);
      setInitialTarget(deepCopyDocument(editor1Ref.current.getDoc()));
      setTargetChangesStatus(TargetSaveState.AUTOSAVED);
    } catch (error) {
      console.error('Error updating suggestions:', error);
    }
  };

  const { isLoading: isSavingSuggestions, executeCallback: receiveSuggestions } =
    useAsyncOperation(handleNewSuggestions);

  const {
    toggleImageTagging,
    isImageTagging,
    isTaggingSlideOverOpen,
    imageTagFormValues,
    openTaggingSlideOver,
    closeTaggingSlideOver,
    handleSubmitTag,
  } = useImageTagging({
    onSubmit: receiveSuggestions,
    selectedTargetNode: currentTargetNodeIds[0],
    updatedAiResponse,
  });

  const handleClosePinningSlideOver = () => {
    closeTaggingSlideOver();
    handleSourceNodeIds();
  };

  const handleGeneralizationAuthoringUndo = () => {
    const suggestion = undo();
    if (!suggestion) return;

    const { targetNodeId = '', targetValue = '' } = suggestion.previous || {};
    const { id } = suggestion.current;

    if (!acceptedSuggestions || !targetNodeId) return;
    const filteredAcceptedSuggestions = acceptedSuggestions.filter(
      (obj: GeneralizationSuggestion) => obj.id !== id,
    );
    if (suggestion.previous.id)
      filteredAcceptedSuggestions.push(suggestion.previous as GeneralizationSuggestion);
    const deletedSuggestion = acceptedSuggestions.find(
      (suggestion: GeneralizationSuggestion) => suggestion.id === id,
    );

    try {
      setTargetElement(editor1Ref, targetValue, targetNodeId);
      setAcceptedSuggestions(filteredAcceptedSuggestions);
      if (!currentTargetNodeIds.includes(targetNodeId)) {
        handleTargetNodeIds([targetNodeId]);
      }

      id &&
        undoSuggestion(id).then((res) => {
          setLastSaved(new Date(res.data.lastSavedDate));
        });
    } catch (error) {
      deletedSuggestion &&
        setTargetElement(editor1Ref, deletedSuggestion.targetValue, targetNodeId);
      setAcceptedSuggestions(acceptedSuggestions);
      console.error('Error deleting suggestions:', error);
    }
  };

  const handleGeneralizationAuthoringRedo = () => {
    const suggestion = redo();
    if (!suggestion) {
      return;
    }

    const { id, order, ...nextSuggestion } = suggestion.next as GeneralizationSuggestion;
    if (!acceptedSuggestions || !nextSuggestion.targetNodeId) return;

    try {
      setTargetElement(editor1Ref, nextSuggestion.targetValue, nextSuggestion.targetNodeId);

      if (!currentTargetNodeIds.includes(nextSuggestion.targetNodeId)) {
        handleTargetNodeIds([nextSuggestion.targetNodeId]);
      }

      id &&
        callSaveSuggestions(
          [
            {
              ...nextSuggestion,
              sessionId,
              documentId: nextSuggestion.sourceFileId,
              order: nextSuggestion.previousSuggestionId ? order : null,
            },
          ],
          AcceptanceMethodEnum.AUTHOR,
          false,
        ).then((suggestions: any) => {
          setHistory((previousHistory) => {
            const newHistory = previousHistory.map((v) => {
              if (v.id === id) {
                v.id = suggestions[0].id;
              }

              if (v.previousSuggestionId === null) {
                v.order = suggestions[0].id;
              }

              if (v.previousSuggestionId === id) {
                v.previousSuggestionId = suggestions[0].id;
                v.order = suggestions[0].order;
              }

              return v;
            });
            return newHistory;
          });
        });
    } catch (error) {
      console.error('Error deleting suggestions:', error);
    }
  };

  const compareDocuments = (): IdChangesMap => {
    if (!initialTarget) return {};
    const originalDoc = deepCopyDocument(initialTarget);
    const updatedDoc = deepCopyDocument(editor1Ref?.current?.getDoc());

    const changes: IdChangesMap = {};

    if (originalDoc && updatedDoc) {
      const updatedNodes = updatedDoc.querySelectorAll<HTMLElement>(
        '[data-nodeId]:not([data-comment])',
      );

      updatedNodes.forEach((updatedNode) => {
        const nodeId = updatedNode.getAttribute('data-nodeId') || '';
        const innerHTML = excludeStyleAttribute(updatedNode.innerHTML);
        const updatedInnerHtml = _.unescape(innerHTML);
        const originalNode = originalDoc.querySelector<HTMLElement>(`[data-nodeId='${nodeId}']`);

        const isEmptyNode = removeSingleBrChild(updatedNode).children.length === 0;

        if (
          isEmptyNode &&
          originalNode &&
          _.unescape(excludeStyleAttribute(originalNode.innerHTML)) !== _.unescape(innerHTML)
        ) {
          changes[nodeId] = {
            old: _.unescape(originalNode.innerHTML),
            updated: updatedInnerHtml,
          };
        } else {
          if (!originalNode) {
            const updatedNodeTextContent = _.unescape(removeChildNodeTextContent(updatedNode));

            if (updatedNodeTextContent) {
              changes[nodeId] = { old: '', updated: updatedInnerHtml };
            }
          } else {
            const oldNodeTextContent = _.unescape(removeChildNodeTextContent(originalNode));
            const updatedNodeTextContent = _.unescape(removeChildNodeTextContent(updatedNode));

            if (oldNodeTextContent !== updatedNodeTextContent) {
              if (originalNode.childElementCount !== updatedNode.childElementCount) {
                const copyNode = updatedNode.cloneNode(true) as HTMLElement;
                for (const child of Array.from(copyNode.children) as HTMLElement[]) {
                  child.removeAttribute('data-newnode');
                }
                changes[nodeId] = {
                  old: originalNode.innerHTML,
                  updated: copyNode.innerHTML,
                };
              } else {
                changes[nodeId] = {
                  old: oldNodeTextContent,
                  updated: updatedNodeTextContent,
                };
              }
            }
          }
        }
      });
    }
    return changes;
  };

  const handleSaveChanges = async (
    afterSaveTargetStatus: TargetSaveState = TargetSaveState.SAVED,
    acceptanceMethod: AcceptanceMethodEnum,
  ) => {
    if (!initialTarget || !editor1Ref?.current?.getDoc()) return;

    const originalDocument = deepCopyDocument(initialTarget);
    const updatedDocument = deepCopyDocument(editor1Ref?.current?.getDoc());
    let updatedCells = [];

    const documentChanges = originalDocument && updatedDocument ? compareDocuments() : {};
    if (sessionId && acceptedSuggestions) {
      updatedCells = getUpdatedCells(
        documentChanges,
        sessionId,
        editor1Ref,
        targetNodeIdSuggestionMap,
        updatedAiResponse,
      );
      setInitialTarget(deepCopyDocument(editor1Ref.current.getDoc()));
      const parser = new DOMParser();
      const doc = parser.parseFromString(initialTarget.body.outerHTML, 'text/html');

      updatedCells.forEach((cell) => {
        const element = doc.querySelector(`[data-nodeid='${cell.targetNodeId}']`) || {};
        const previousValues = {
          sessionId: sessionId,
          targetNodeId: cell.targetNodeId,
          sourceNodeId: '',
          documentId: cell.documentId,
          targetValue: (element as HTMLElement).innerHTML,
          mapperType: cell.mapperType,
          sourceValue: '',
          id: null,
        } as NewSuggestion;
        addHistory([previousValues]);
      });

      callSaveSuggestions(updatedCells, acceptanceMethod, false).then(() => {
        setTargetChangesStatus(afterSaveTargetStatus);
        const editor = editor1Ref.current as TinyMCEEditor;
        if (editor && !_.keys(compareDocuments()).length) {
          editor.isNotDirty = true;
          editor.save();
        }
      });
    }
  };

  const { isLoading: isSaving, executeCallback: handleSave } = useAsyncOperation(handleSaveChanges);

  const runAutoSave = !(
    mappingType === MappingTypeEnum.AUTO_MAPPING ||
    isSaving ||
    isSavingSuggestions ||
    isAutoSave ||
    isAutofill
  );

  const isDocumentChanged = () => {
    const newNode = editor1Ref?.current?.getBody()?.querySelector('[data-newnode]');
    const deletedNodes = getDeletedNodes(initialTarget, editor1Ref?.current?.getDoc());
    return _.keys(compareDocuments()).length || newNode || deletedNodes.length;
  };

  const handleEditorChange = useCallback(() => {
    if (!runAutoSave) return;
    // Update timestamp of last change
    setLastChangeTime(Date.now());
  }, [runAutoSave]);

  useEffect(() => {
    if (lastChangeTime === null || !runAutoSave) return;

    // Always clear any existing timer first
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
    }

    if (isDocumentChanged()) {
      setTargetChangesStatus(TargetSaveState.UNSAVED);
      // Set new timer
      timerRef.current = setTimeout(async () => {
        try {
          await handleSave(TargetSaveState.AUTOSAVED, AcceptanceMethodEnum.AUTO_SAVE);
        } finally {
          setLastChangeTime(null);
          timerRef.current = undefined;
        }
      }, AUTOSAVE_DELAY);
    } else {
      // No changes, reset states
      setLastChangeTime(null);
      setTargetChangesStatus(TargetSaveState.UNCHANGED);
    }

    // Cleanup on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, [lastChangeTime, runAutoSave, isDocumentChanged, handleSave]);

  const handleAiDropDownChange = (option: any) => {
    setSuggestionIndex((prev: any) => ({ ...prev, value: option.value, nodeId: option.nodeId }));
  };

  const setAllCells = () => {
    setCells(getAllCells(editor1Ref.current.getBody().outerHTML));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'g') setIsCtrlPressed(false);
    else setIsCtrlPressed(event.ctrlKey || event.metaKey);
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (
      event.code === 'ControlLeft' ||
      event.code === 'ControlRight' ||
      event.code === 'MetaLeft' ||
      event.code === 'MetaRight'
    ) {
      setIsCtrlPressed(false);
    }
  };

  const handleLinkNodes = async () => {
    let targetValue;
    let sourceValue;
    const updatedSelection = linkTextSelectionNode();
    if (updatedSelection) {
      const { targetValue: targetVal, sourceValue: sourceVal } = updatedSelection;
      targetValue = targetVal;
      sourceValue = sourceVal;
    } else {
      await handleSaveChanges(undefined, AcceptanceMethodEnum.AUTHOR);
      targetValue = getElement(editor1Ref, currentTargetNodeIds[0])?.innerHTML;
    }
    if (currentTargetNodeIds.length === 1 && currentSourceNodeIds.length === 1) {
      const suggestion = {
        sessionId: sessionId,
        targetNodeId: currentTargetNodeIds[0],
        sourceNodeId: currentSourceNodeIds[0],
        documentId: getCatalogId(editor2Ref, currentSourceNodeIds[0]),
        sourceFileId: getCatalogId(editor2Ref, currentSourceNodeIds[0]),
        targetValue: targetValue,
        mapperType: 'author',
        ...(!!sourceValue && { sourceValue }),
      };

      callSaveSuggestions([suggestion], AcceptanceMethodEnum.AUTHOR, false);
    }
  };

  // TODO: remove this code when implementing new comments version
  const displayCommentButton = !!(
    currentTargetNodeIds.length === 1 && targetNodeIdSuggestionMap[currentTargetNodeIds[0]]
  );

  const currentSuggestionId = targetNodeIdSuggestionMap[getCurrentNodeId(currentTargetNodeIds[0])]
    ? targetNodeIdSuggestionMap[getCurrentNodeId(currentTargetNodeIds[0])].id
    : null;

  const { commentModal, openCommentModalWithThread } = useGeneralizationComments(
    currentSuggestionId ?? 0,
  );
  //

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleAiResponseUpdate = (
    isSnappingEnabled: boolean,
    clearAISuggestions: boolean,
    aiResponseCopy: AIResponse,
  ) => {
    if (isSnappingEnabled) {
      highlightAllSourceDocumentSuggestions();
    }

    if (clearAISuggestions) {
      const tdNodeIds = aiResponseCopy && getCellElementIds(aiResponseCopy);
      tdNodeIds && removeHighlightSuggestions(editor1Ref, tdNodeIds);
    }

    highlightAllTargetSuggestions(editor1Ref);
  };

  useEffect(() => {
    if (aiResponse && acceptedSuggestions) {
      const aiSuggestionWithId = lockSourceFile
        ? filteredAiResponse(newAiResponse, selectedCatalogId)
        : newAiResponse;
      setUpdatedAiResponse(aiSuggestionWithId);
      setLockedAiResponse(newAiResponse);
      aiResponseCopy &&
        handleAiResponseUpdate(isSnappingEnabled, clearAISuggestions, aiResponseCopy);
    }
  }, [aiResponse, acceptedSuggestions]);

  useEffect(() => {
    if (!clearAISuggestions) {
      highlightAllTargetSuggestions(editor1Ref);
    }
  }, [generatedDocument]);

  useEffect(() => {
    if (lockSourceFile) {
      const filteredResponse = filteredAiResponse(lockedAiResponse, selectedCatalogId);
      setUpdatedAiResponse(filteredResponse);
    } else {
      setUpdatedAiResponse(lockedAiResponse);
    }
  }, [lockSourceFile]);

  const selectedNodesRatio = `${currentSourceNodeIds.length} / ${currentTargetNodeIds.length}`;

  const disableLinkButton = !(
    currentTargetNodeIds.length === 1 && currentSourceNodeIds.length === 1
  );

  const svgValues = useMemo(() => {
    const values: Record<string, any> = {};
    Object.entries(updatedAiResponse).forEach(([targetId, sourceNodes]) => {
      sourceNodes.forEach((targetNode: Record<string, any>) => {
        const [key, value] = Object.entries(targetNode)[0];
        const sourceNode = { ...value, targetId };

        if (values[key]) {
          values[key].push(sourceNode);
        } else {
          values[key] = [sourceNode];
        }
      });
    });
    return values;
  }, [updatedAiResponse]);

  const memoizedCatalogWithSourceIds = useMemo(() => {
    return getCatalogWithSourceIdsFromAIResponse(updatedAiResponse);
  }, [updatedAiResponse]);

  const getSourceCatalogId = () => {
    const sourceNodeIdValue = sourceNodeId()?.at(0);
    if (sourceNodeIdValue && sourceNodeIdValue === currentSourceNodeIds[0]) {
      return sourceFileId();
    } else if (aiResponse) {
      const matchingNode = aiResponse[currentTargetNodeIds[0]]?.find(
        (s) => Object.keys(s)[0] === currentSourceNodeIds[0],
      );

      if (matchingNode) {
        return matchingNode[currentSourceNodeIds[0]].sourceFileId;
      }
    }
  };

  useEffect(() => {
    if (!loadingGeneratedDocument) {
      setEditor1Content({
        html: generatedDocument ? generatedDocument.html : '',
      });
    }
  }, [generatedDocument]);

  useEffect(() => {
    if (!loadingGeneratedDocument && !loadingSourceDocument) {
      setIsLoading(false);
    }
  }, [loadingGeneratedDocument, loadingSourceDocument]);

  useEffect(() => {
    const sourceNodeIds = suggestionIndex.nodeId ? [suggestionIndex.nodeId] : sourceNodeId();
    if (sourceNodeIds && sourceNodeIds.length > 0) {
      setCurrentSourceNodeIds((previousSourceNodeIds) => {
        setPreviousSourceNodeIds(previousSourceNodeIds);
        return sourceNodeIds ?? [];
      });
      setIsDisabledApprove(true);
    }
  }, [suggestionIndex]);

  useEffect(() => {
    if (currentSourceNodeIds.length === 1) {
      const catId = isSearched
        ? searchResult.length &&
          searchResult.find((result) => result.dataNodeId === suggestionIndex.nodeId)?.catalog_id
        : getSourceCatalogId();
      if (catId && (selectedCatalogId !== +catId || +selectedValue !== +catId)) {
        handleSourcechange(catId?.toString());
      }
    }
    previousSourceNodeIds.forEach((previousSourceNodeId) => {
      const element = getElement(editor2Ref, previousSourceNodeId);
      const allNodesIds = getAllNodesIdsInElement(element);
      const needHighlighting =
        memoizedCatalogWithSourceIds[selectedCatalogId] &&
        allNodesIds.some(
          (nodeId) => memoizedCatalogWithSourceIds[selectedCatalogId].indexOf(nodeId) !== -1,
        );
      element && highlightPreviousNode(editor2Ref, needHighlighting, element);
    });
    currentSourceNodeIds.forEach((currentSourceNodeId, index) => {
      const element = getElement(editor2Ref, currentSourceNodeId);
      highlightCurrentNode(editor2Ref, colors[index], element);
    });

    highlightMatchingElements(editor1Ref, editor2Ref, currentTargetNodeIds);
    editor2Ref?.selection?.clearDrag();
  }, [currentSourceNodeIds]);

  useEffect(() => {
    if (!_.isEmpty(currentSourceNodeIds)) {
      currentSourceNodeIds.forEach((currentSourceNodeId, index) =>
        highlightCurrentNode(
          editor2Ref,
          colors[index],
          getElement(editor2Ref, currentSourceNodeId),
        ),
      );
      highlightMatchingElements(editor1Ref, editor2Ref, currentTargetNodeIds);
    }
  }, [sourceFileDoc]);

  useEffect(() => {
    !isNextAuthoringCellAvailable && setIsDisabledApprove(true);

    if (currentTargetNodeIds.length === 1) {
      const currentElement = getElement(editor1Ref, currentTargetNodeIds[0]) as HTMLElement;
      const cellElement = getCellElement(currentElement, editor1Ref?.current?.getBody() as Node);
      if (
        cellElement?.tagName === 'TH' ||
        (cellElement?.tagName === 'TD' && cellElement?.cellIndex === 0)
      ) {
        setIsHeaderCellSelected(true);
      } else {
        setIsHeaderCellSelected(false);
      }
      setSuggestionIndex((prev: { value: number }) => ({
        ...prev,
        value: getSourceNodeIndex(getCurrentNodeId(currentTargetNodeIds[0]), updatedAiResponse),
        nodeId: '',
      }));

      if (aiResponse) {
        setAiSuggestionList(aiResponse[getCurrentNodeId(currentTargetNodeIds[0])]);
      }
    } else if (currentTargetNodeIds.length > 1) {
      setIsHeaderCellSelected(true);
    } else {
      setIsHeaderCellSelected(false);
    }

    previousTargetNodeIds.forEach((previousTargetNodeId) => {
      const element = getElement(editor1Ref, previousTargetNodeId);
      const cellElement = element && getCellElement(element, editor1Ref);
      highlightPreviousNode(editor1Ref, false, cellElement ?? element);
    });
    highlightCurrentNodes();
  }, [currentTargetNodeIds]);

  useEffect(() => {
    const isDisabled =
      _.isEmpty(currentTargetNodeIds) ||
      _.isEmpty(currentSourceNodeIds) ||
      currentTargetNodeIds.length !== currentSourceNodeIds.length ||
      !!sourceSelection !== !!targetSelection;

    setIsDisabledApprove(isDisabled);
  }, [currentTargetNodeIds, currentSourceNodeIds, sourceSelection, targetSelection]);

  useEffect(() => {
    if (sessionId) {
      fetchMappingSessionComments(sessionId)
        .then((res) => {
          setMappingComments([...res.data]);
        })
        .catch((err) => console.error(err));
    }
  }, [sessionId]);

  return {
    isLoading,
    isDisable,
    isDisabledApprove,
    setIsDisable,
    highlightAllTargetSuggestions,
    highlightAllSourceDocumentSuggestions,
    receiveSuggestions,
    autofillSuggestions,
    isUndoAvailable,
    isRedoAvailable,
    handleGeneralizationAuthoringUndo,
    handleGeneralizationAuthoringRedo,
    targetOnClickHandler,
    sourceDocumentOnClickHandler,
    aiSuggestionList,
    handleAiDropDownChange,
    setCurrentTargetNodeIds,
    setPreviousTargetNodeIds,
    handleSave,
    isSaving,
    handleKeyDown,
    handleKeyUp,
    selectedNodesRatio,
    setSearchResult,
    setIsSearched,
    suggestionNodeValues,
    disableLinkButton,
    handleLinkNodes,
    isImageTagging,
    toggleImageTagging,
    suggestionsWithColors,
    updatedAiResponse,
    setUpdatedAiResponse,
    isNextAuthoringCellAvailable,
    isPreviousAuthoringCellAvailable,
    navigateToAuthoringCell,
    getCurrentNodeId,
    handleReset,
    lockedAiResponse,
    getCellElementIds,
    handleLockedFileChange,
    handleSnappingToggle,
    isSnappingEnabled,
    setCopiedAIResponse,
    copiedAIResponse,
    handleTargetNodeIds,
    currentTargetNodeIds,
    isSavingSuggestions,
    isHeaderCellSelected,
    callSaveSuggestions,
    setColors,
    isTaggingSlideOverOpen,
    handleClosePinningSlideOver,
    handleSubmitTag,
    imageTagFormValues,
    targetChangesStatus,
    setTargetChangesStatus,
    openCommentModalWithThread,
    commentModal,
    displayCommentButton,
    isNewSuggestionsActive,
    setIsNewSuggestionsActive,
    autoFillHandler,
    isImageNodeSelected,
    handleSourceNodeIds,
    handleEditorChange,
  };
};
