// React related imports
import { useEffect, FC } from 'react';
import { Controller } from 'react-hook-form';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

// Components
import { Input, TagsField } from '@GxT-Team/gxt-components';
import { InputError } from '../../components/inputError';
import useModal from '../../hooks/useModal';
import CatalogItemSlideOver from '../../components/CatalogItemSlideOver';

// Types
import { ITemplateFields } from './types';
import { ItemType } from '../../services/api/types';
import { SourceFileGroup } from '../upload';
// apicalls
import { getAllCatalogItems } from '../settings/components/utils/apiHelpers';

// utils
import { generateTagsFieldOptions } from './utils/helpers';

const TemplateFields: FC<ITemplateFields> = ({ control, errors, setValue, external }) => {
  const catalogItemSlideOver = useModal();
  const [selectedRegions, setSelectedRegions] = useState<SourceFileGroup[]>([]);
  const [selectedCTDSections, setSelectedCTDSections] = useState<SourceFileGroup[]>([]);
  const [isRegionModal, setIsRegionModal] = useState<boolean>(true);
  const handleCloseCreateItem = () => {
    catalogItemSlideOver.closeModal();
  };

  const { data, isLoading } = useQuery({
    queryKey: [isRegionModal ? ItemType.Region : ItemType.CTDSection],
    queryFn: () => {
      const type = isRegionModal ? ItemType.Region : ItemType.CTDSection;
      return getAllCatalogItems(type, true);
    },
  });

  const handleOpenRegionModal = () => {
    setIsRegionModal(true);
    catalogItemSlideOver.openModal();
  };
  const handleResetSelection = () =>
    isRegionModal ? setSelectedRegions([]) : setSelectedCTDSections([]);

  const handleOpenCTDSectionsModal = () => {
    setIsRegionModal(false);
    catalogItemSlideOver.openModal();
  };

  const updateFormValue = (field: string, value: SourceFileGroup[]) => {
    setValue(field, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (selectedRegions.length > 0) {
      updateFormValue('regions', selectedRegions);
    }
  }, [selectedRegions]);

  useEffect(() => {
    if (selectedCTDSections.length > 0) {
      updateFormValue('ctdSections', selectedCTDSections);
    }
  }, [selectedCTDSections]);

  return (
    <form className="flex flex-col items-start gap-4 w-2/5 self-stretch">
      <p className="text-lg font-bold leading-6 tracking-tighter text-primary-300">
        {` ${external ? 'External Document' : 'Template'} information`}
      </p>
      <div className="flex flex-col items-start gap-1 w-full">
        <Controller
          name="templateName"
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <Input
                {...field}
                inputSize={'small'}
                label={`${external ? 'External' : ''} Document name*`}
                primary
                placeholder="Document name"
                customStyles={{
                  container: { width: '100%' },
                  input: { borderRadius: '0.375rem' },
                  label: { color: 'var(--Primary-300, #115873)' },
                }}
              />
              <InputError errors={errors} field={'templateName'} />
            </div>
          )}
        />
      </div>
      <div className="flex flex-col items-start gap-1 w-full">
        <Controller
          name="ctdSections"
          control={control}
          render={({ field }) => {
            const ctdSectionListOptions = generateTagsFieldOptions(selectedCTDSections);
            return (
              <div className="w-full" onClick={handleOpenCTDSectionsModal}>
                <TagsField
                  {...field}
                  value={ctdSectionListOptions}
                  onClick={handleOpenRegionModal}
                  label="CTD Section*"
                />
                <InputError errors={errors} field={'ctdSections'} />
              </div>
            );
          }}
        />
      </div>
      <div className="flex items-start gap-4 self-stretch">
        <Controller
          name="version"
          control={control}
          render={({ field }) => (
            <div className="w-1/2">
              <Input
                {...field}
                inputType={'number'}
                min={0}
                step={0.1}
                inputSize={'small'}
                label={'Version*'}
                primary
                placeholder="Version"
                customStyles={{
                  container: { marginRight: '0.5rem' },
                  input: { borderRadius: '0.375rem' },
                  label: { color: 'var(--primary-300, #115873)' },
                }}
              />
              <InputError errors={errors} field={'version'} />
            </div>
          )}
        />
        <Controller
          name="regions"
          control={control}
          render={({ field }) => {
            const regionListOptions = generateTagsFieldOptions(selectedRegions);
            return (
              <div className="w-full" onClick={handleOpenRegionModal}>
                <TagsField
                  {...field}
                  value={regionListOptions}
                  onClick={handleOpenRegionModal}
                  label="Region*"
                />
                <InputError errors={errors} field={'regions'} />
              </div>
            );
          }}
        />
        {
          // TODO: This dropdown will be used for Section in future
          // <Dropdown
          //   customStyles={{
          //     button: { width: '100%', height: '36px' },
          //     container: { flex: 1 },
          //     placeholder: { justifyContent: 'flex-start' },
          //     label: { color: 'var(--Primary-300, #115873)', fontWeight: '700' },
          //   }}
          //   dropdownIcon
          //   placeholder="Section"
          //   label="Section*"
          //   onSelect={() => {}}
          //   options={['Option 1', 'Option 2', 'Option 3 is a very big option']}
          //   position="bottom"
          //   type="select"
          // />
        }
      </div>
      {catalogItemSlideOver.show && (
        <CatalogItemSlideOver
          onClose={handleCloseCreateItem}
          isOpen={catalogItemSlideOver.show}
          heading={isRegionModal ? 'Select Regions' : 'Select CTD Sections'}
          catalogItems={data || []}
          selectedItems={isRegionModal ? selectedRegions : selectedCTDSections}
          handleAdd={isRegionModal ? setSelectedRegions : setSelectedCTDSections}
          handleResetSelection={handleResetSelection}
          isSingleSelect={!isRegionModal}
          isLoading={isLoading}
        />
      )}
      <p className="text-primary-200 text-xs not-italic font-normal leading-[1.125rem] tracking-[0.00088rem]">
        Items marked with a * are required
      </p>
    </form>
  );
};

export default TemplateFields;
