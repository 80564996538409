import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layout';
import { Header, Button } from '@GxT-Team/gxt-components';
import { CreateUserForm } from './components/CreateUserForm';
import { useRef } from 'react';
import { UserDefaultValues } from './components/CreateUserForm/utils';
import { CreateUserFormValues } from './components/CreateUserForm/CreateUserForm.types';
import { useUser } from '../../services/api/user';

const CreateUser = () => {
  const navigate = useNavigate();
  const formRef = useRef<{ submitForm: () => void }>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const updateFormValidity = (isValid: boolean) => {
    setIsFormValid(isValid);
  };

  const { createUser } = useUser();

  const createNewUser = async (data: CreateUserFormValues) => {
    const { firstName, lastName, email, avatar, roles } = data;

    // Note: This is a temporary solution to generate a unique username - ideally we should use the email as the username in the User Pool
    const userName = `${firstName}-${lastName}-${Date.now()}`.replace(/\s/g, '');

    const formObj = new FormData();
    formObj.append('userName', userName);
    formObj.append('firstName', firstName);
    formObj.append('lastName', lastName);
    formObj.append('email', email);
    avatar && formObj.append('avatar', avatar);
    formObj.append('roles', JSON.stringify(roles));
    createUser.mutateAsync(formObj).then(() => navigate('/settings'));
  };

  return (
    <Layout>
      <Layout.Header>
        <Header>
          <Header.Heading>Catalog</Header.Heading>
        </Header>
      </Layout.Header>
      <Layout.Body>
        <div>
          <p className="text-primary-200 font-bold text-[1.25rem] leading-[1.875rem] tracking-[0.0015rem]">
            Create new user
          </p>
          <p className="text-neutral-400 font-normal text-[1rem] leading-[1.875rem] tracking-[0.0015rem]">
            All fields are required
          </p>
        </div>
        <CreateUserForm
          handleSubmitData={createNewUser}
          defaultValues={UserDefaultValues}
          ref={formRef}
          updateFormValidity={updateFormValidity}
        />
        <div className="flex gap-6 justify-end w-full">
          <Button
            variant="secondary"
            onClick={handleSubmit}
            disabled={!isFormValid || createUser.isPending}>
            Create
          </Button>
          <Button variant="secondary" onClick={() => navigate('/settings')}>
            Cancel
          </Button>
        </div>
      </Layout.Body>
    </Layout>
  );
};

export default CreateUser;
