import { Input, TagsField } from '@GxT-Team/gxt-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputError } from '../../../../components/inputError';
import { copyDocumentSchema } from './schema';
import { CopyDocumentDefaultValues, CopyDocumentFormProps, InputProps } from './types';

const CopyDocumentForm = forwardRef<unknown, CopyDocumentFormProps>(
  (
    { defaultValues, submitData, openProjectSlideOver, openCTDSectionSlideOver, setDefaultData },
    ref,
  ) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      resolver: zodResolver(copyDocumentSchema),
      mode: 'onChange',
      defaultValues,
    });

    useEffect(() => {
      for (const key in defaultValues) {
        setValue(
          key as keyof CopyDocumentDefaultValues,
          defaultValues[key as keyof CopyDocumentDefaultValues],
        );
      }
    }, [defaultValues, setValue]);

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(submitData),
    }));

    const inputs: InputProps<CopyDocumentDefaultValues>[] = [
      {
        name: 'title',
        label: 'Document Name',
        type: 'text',
        placeholder: 'Enter a title',
        onClick: () => {},
      },
      {
        name: 'project',
        label: 'Project',
        type: 'tags',
        placeholder: 'Select a Project',
        onClick: openProjectSlideOver,
      },
      {
        name: 'ctdSections',
        label: 'CTD Section',
        type: 'tags',
        placeholder: 'Select a CTD Section',
        onClick: openCTDSectionSlideOver,
      },
      {
        name: 'version',
        label: 'Version',
        type: 'number',
        placeholder: '1.00',
        onClick: () => {},
      },
    ];

    return (
      <form className="flex flex-col gap-4 self-stretch">
        {inputs.map((input, index) => (
          <Controller
            key={index}
            name={input.name}
            control={control}
            render={({ field }) => {
              if (typeof field.value === 'string' || typeof field.value === 'number')
                return (
                  <div className="w-full">
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setDefaultData(field.name, e.target.value);
                      }}
                      label={input.label}
                      type={input.type}
                      step={0.1}
                      min={0.1}
                      className="w-full"
                      value={field.value}
                      placeholder={input.placeholder}
                      onClick={input.onClick}
                      customStyles={{ input: { height: '2.625rem' } }}
                      error={!!errors[input.name]?.message}
                      helpText={<InputError errors={errors} field={input.name} />}
                    />
                  </div>
                );
              else
                return (
                  <div className="w-full">
                    <TagsField
                      {...field}
                      value={field.value}
                      label={input.label}
                      onClick={input.onClick}
                      error={!!errors[input.name]?.message}
                      helpText={<InputError errors={errors} field={input.name} />}
                    />
                  </div>
                );
            }}
          />
        ))}
      </form>
    );
  },
);

CopyDocumentForm.displayName = 'CopyDocumentForm';

export default CopyDocumentForm;
