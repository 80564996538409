// React-related imports
import { FC } from 'react';
import { Controller } from 'react-hook-form';

// Component imports
import { Input } from '@GxT-Team/gxt-components';
import { InputError } from '../../../../../../components/inputError';

// Types
import { DataLocationProps } from './DataLocation.types';
import { ImageTagFormValues } from '../../../../types';

// Other dependencies
import _ from 'lodash';

const DataLocation: FC<DataLocationProps> = ({
  control,
  errors,
  prefix,
  requiredFieldLabel,
  requiredFieldName,
}) => {
  return (
    <>
      <p className="text-lg text-primary-200">{_.capitalize(prefix)} Data</p>
      <div className="flex flex-col items-start gap-4 self-stretch">
        <Controller
          name={requiredFieldName}
          control={control}
          rules={{ required: `${requiredFieldLabel} is required` }}
          render={({ field }) => (
            <div className="w-full">
              <Input
                {...field}
                id={requiredFieldName}
                label={`${requiredFieldLabel}*`}
                placeholder={requiredFieldLabel}
                isFilled={!!field.value}
                customStyles={{
                  input: { height: '2.625rem' },
                }}
                error={!!errors[requiredFieldName]?.message}
                helpText={<InputError errors={errors} field={`${requiredFieldName}`} />}
              />
            </div>
          )}
        />
        <div className="flex items-start gap-5 self-stretch">
          <Controller
            name={`${prefix}TableNumber` as keyof ImageTagFormValues}
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Input
                  {...field}
                  label="Table Number"
                  id={`${prefix}TableNumber`}
                  placeholder="Table Number"
                  type="number"
                  isFilled={!!field.value}
                  customStyles={{
                    input: { height: '2.625rem' },
                  }}
                  error={!!errors[`${prefix}TableNumber` as keyof ImageTagFormValues]?.message}
                  helpText={<InputError errors={errors} field={`${prefix}TableNumber`} />}
                />
              </div>
            )}
          />
          <Controller
            name={`${prefix}TableColumn` as keyof ImageTagFormValues}
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Input
                  {...field}
                  id={`${prefix}TableColumn`}
                  label="Table Column"
                  placeholder="Table Column"
                  type="number"
                  isFilled={!!field.value}
                  customStyles={{
                    input: { height: '2.625rem' },
                  }}
                  error={!!errors[`${prefix}TableColumn` as keyof ImageTagFormValues]?.message}
                  helpText={<InputError errors={errors} field={`${prefix}TableColumn`} />}
                />
              </div>
            )}
          />
        </div>
        <Controller
          name={`${prefix}TableRow` as keyof ImageTagFormValues}
          control={control}
          render={({ field }) => (
            <div className="w-full">
              <Input
                {...field}
                id={`${prefix}TableRow`}
                label="Table Row"
                placeholder="Table Row"
                type="number"
                isFilled={!!field.value}
                customStyles={{
                  input: { height: '2.625rem' },
                }}
                error={!!errors[`${prefix}TableRow` as keyof ImageTagFormValues]?.message}
                helpText={<InputError errors={errors} field={`${prefix}TableRow`} />}
              />
            </div>
          )}
        />
      </div>
    </>
  );
};

export default DataLocation;
